import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import Swal from 'sweetalert2'

export default function TemplateEmail() {
    const [loading, setLoading]                            = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])

    const getTemplateEmail= async() => {
        await api.get('/status-integration-email/0')
                 .then(response => {
                    console.log(response.data)
                     setGridParams({
                                     idemailtemplate     : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                                         value : '00000'},
                                                            primaryKey  : true,
                                                            visible : false},
                                     description         : {text        : "Descrição",
                                                            searchDefault : true},
                                     emailtemplatestatus : {text        : "Status",
                                                            item        : {className : "text-center",
                                                            switch      : {data  : response.data,
                                                                           key   : 'idstatusintegrationmail'}}},
                                     Options      : {text        : "Opções",
                                                     className   : "text-center",
                                                     order       : false,
                                                     filter      : false,
                                                     item        : [{type  : "Custom",
                                                                     props : {tag               : "button",
                                                                              title             : "Exclusão de template",
                                                                              className         : 'btn btn-sm btn-danger',
                                                                              icon              : "fa fa-trash",
                                                                              indexcallback     : 0}},
                                                                    {type  : "Custom",
                                                                     props : {tag               : "button",
                                                                              title             : "Editar template",
                                                                              className         : 'btn btn-sm btn-warning',
                                                                              icon              : "fa fa-edit",
                                                                              indexcallback     : 1}},
                                                                    {type  : "Custom",
                                                                     props : {tag               : "button",
                                                                              title             : "Inativar template",
                                                                              className         : 'btn btn-sm btn-secondary',
                                                                              visualConditional : {idstatusintegrationmail : {operator : '==',
                                                                                                              values : 1}},
                                                                              icon              : "fa fa-ban",
                                                                              indexcallback     : 3}},
                                                                    {type  : "Custom",
                                                                     props : {tag               : "button",
                                                                              title             : "Ativar template",
                                                                              className         : 'btn btn-sm btn-success',
                                                                              visualConditional : {idstatusintegrationmail : {operator : '==',
                                                                                                              values : 0}},
                                                                              icon              : "fa fa-check",
                                                                              indexcallback     : 2}}]}
                                    })  
                })

    }

    useEffect(() => {
        getTemplateEmail()
        setStatistic('maintenance\\template-email')
    },[])
    
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}

    const RemoveTemplate   = async (id) => {    
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir o template?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/email-template/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
        }) 
    }

    const EditTemplate = async(id) => {
        window.addTab(null, 'Alteração de Template', 'fa fa-envelope', '/template-editor',{idemailtemplate : id,editState : true},0,true)
    }

    const ActiveTemplate = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente ativar o template?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/email-template-status/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
        }) 
    }

    const InactiveTemplate = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente desativar o template?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/email-template-status/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
        }) 
    }

    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading}/>
            )}

            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div  className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-success" title="Insere um novo registro"  onClick={e => (window.addTab(e, 'Novo Template', 'fa fa-envelope', '/template-editor',{editState : false},0,true))}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/email-template' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      callbackShowButtons = {setShowButtons}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackExcel       = {setExcel}
                      callbackCellClick   = {EditTemplate}
                      filename            = "Template Email"
                      callbackButtons     = {[RemoveTemplate, 
                                              EditTemplate,
                                              ActiveTemplate,
                                              InactiveTemplate]}
                      />  
               
            </div>                    
        </ContentWrapper>
    )
}