import React, { useEffect, useState } from 'react';
import { Collapse, Badge } from 'reactstrap';
import {Dropdown} from 'react-bootstrap'

import SidebarRun from './Sidebar.run';
import api from '../../services/api';
import {isAuthenticated, getUserData} from '../../services/auth';
import Loading from '../../components/Loading';
import ChangePassword from '../modals/modal-changePassword'
import ShowMessages from './../../components/Exceptions'
import {getDataDB } from '../../services/idb'
import { IDCOMPANY, ReadDataStorage, IDPLAN} from './../../services/auth'

import USER_NO_PHOTO from './../../assets/user-no-photo.jpg'


const SidebarItemHeader = ({item}) => (
    <li className="nav-heading">
        <span>{item.heading}</span>
    </li>
)

const SidebarItem = ({item, isActive, collapse}) => (
    <li className={ isActive ? 'active' : '' } id={`menu-${item.idmenu}`} >
        <a href="/#" title={item.description} data-title={item.name} 
                                              data-icon={item.icon} 
                                              data-path={item.path} 
                                              data-idmenu={item.idmenu} 
                                              onClick={e => {
                                                if (document.querySelector('body').classList.length > 0)
                                                    document.querySelector('body').classList = ''
                                                  window.addTab(e, item.name, item.icon, item.path,{},item.idmenu,item.uniquetab)  
                                              }}>
            {(!collapse) && <em className="arrow-right" style={{fontSize:"14px"}}></em>}
            {(collapse) ? <em className={item.icon} style={{color:item.idmenu > 5000 ? "#9a3fd8" : "#272829"}}></em> : ""}
            <span style={{whiteSpace: "break-spaces", color:item.idmenu > 5000 ? "#9a3fd8" : "#272829"}}>{item.name}</span>   
            {item.label && <div style={{width:"45%", display:"flex", justifyContent:"flex-end", alignItems:"center"}}><Badge tag="div" className="float-right" style={{width:"fit-content"}}  color={item.label.color}>{item.label.value}</Badge></div>}                
        </a>   
    </li>
)

const SidebarSubItem = ({item, isActive, handler, children, isOpen, idPlan}) => (

    <li className={ isActive ? 'active' : '' } >
        <div className="nav-item" onClick={ handler }>
            {item.icon && <em className={item.icon}></em>}
            <span>{item.name}</span>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {!item.idmodule.includes(idPlan) && 
                <div className='bg bg-warning' style={{padding:"1px 8px", borderRadius:"8px", fontSize:"10px", width:"70px", display:"flex",gap:"2px", alignItems:"center", position:"absolute", marginLeft:"80px", marginTop:"15px"}}>
                    <i className='fa fa-crown' style={{color:"#272829"}}/>
                    <span style={{color:"#272829"}}>Upgrade</span>
                </div> 
            }
            <span className={ isOpen ? 'arrow open' : 'arrow' }></span>
        </div>
        <Collapse isOpen={ isOpen } >
            <ul id={item.path} className="sidebar-nav sidebar-subnav" >
                { children }
            </ul>
        </Collapse>
    </li>
)

const SidebarSubHeader = ({item}) => (
    <li className="sidebar-subnav-header">{item.name}</li>
)

const closeSidebar = () => {
    this.props.actions.toggleSetting('asideToggled');
}

const routeActive = (paths) => {
    if (paths.length === 0)
        return false

    paths = Array.isArray(paths) ? paths : [paths];
    return paths.some(p => window.location.pathname.indexOf(p) > -1)
}

const itemType = item => {
    if (item.heading) return 'heading';
    if (!item.submenu) return 'menu';
    if (item.submenu) return 'submenu';
}

const getSubRoutes = item => item.submenu.map(({path}) => path)

export default function Sidebar({ActualCompany}) {
    const [collapse, setCollapse]             = useState({})
    const [menu, setMenu]                     = useState([])
    const [loading, setLoading]               = useState(false)
    const [localDatabase, setLocalDatabase]   = useState('')
    const [userName, setUserName]             = useState('')
    const [occupation, setOccupation]         = useState('')
    const [urlPhoto, setUrlPhoto]             = useState('')
  
    const [idPlan, setIDPlan]                 = useState(0)

    const [changePassword, setChangePassword] = useState(false)

    const handleCloseModal = () => {setChangePassword(false)}

    const buildCollapseList = (Menu) => {
        let _collapse = {};

        Menu
            //.filter(({heading}) => !heading)
            .forEach(({name, path, submenu}) => {
                _collapse[name] = routeActive(submenu ? submenu.map(({path})=>path) : path)
            })
            
        setCollapse({_collapse});  
    }

    const toggleItemCollapse = (stateName) => {
        var _collapse = collapse;

        if (_collapse['_collapse'] !== undefined)
            _collapse = _collapse['_collapse'];

        for (let c in _collapse) {
            if (_collapse[c] === true && c !== stateName)
                _collapse[c] =  false
        }

        _collapse[stateName] = !_collapse[stateName]
        setCollapse({_collapse})
    }

    const _sidebar = async() => {
        let _i = 0
        if (isAuthenticated() && getUserData() !== null) {
            setLoading(true)
            setUrlPhoto(USER_NO_PHOTO)

            let url
            
            await api.get('/menu-permissions')
                     .then(response => {    
                         buildCollapseList(response.data) 
                         setMenu(response.data)
                                                   
                         _i += 1
                         if (_i === 3)
                            setLoading(false) 
                     }).catch(error => {
                         setMenu([])
                         ShowMessages(error)
                     }) 
                      
            if (getUserData() !== null) {
                await api.get('/user/' + getUserData()['iduser'])
                         .then(response => {   
                            let _data = response.data 
                         
                            if (_data.pathphoto !== "") 
                                url = _data.pathphoto
                            setOccupation(_data.occupation)    
                            //setUserName(_data.name !== "" ? _data.name.length > 20 ? _data.name.substring(0,20) + '...' : _data.name : "")   
                            setUserName(_data.name )
                            _i += 1   
                            if (_i === 3)
                                setLoading(false) 
                        }).catch(error => {
                            ShowMessages(error)
                        })  
            }      

            if (url !== undefined) 
                await api.get( url,{
                    responseType: 'arraybuffer'
                })
                         .then(response => {  
                             if (response.data === null)
                                setUrlPhoto(USER_NO_PHOTO)
                             else 
                                setUrlPhoto('data:image/png;base64,' + response.data)  
                            _i += 1
                            if (_i === 3)
                                setLoading(false)      
                        }).catch(error => {
                            ShowMessages(error)
                        }) 
            else if (_i === 2)
                setLoading(false)

            document.querySelector('.wrapper').style.backgroundColor ="#2473e0"
            setIDPlan(parseInt(JSON.parse(ReadDataStorage(IDPLAN)).idplan))
        }
    }

    useEffect(() => {
        document.querySelector('.wrapper').style.backgroundColor ="#fff"
        const getDatabaseVersion = async() => {
            let _version = await getDataDB('DBVersion',ReadDataStorage(IDCOMPANY)) || ''
            setLocalDatabase(_version)
        }
        setLoading(true)
        getDatabaseVersion()
        if (ActualCompany >= 0) 
            _sidebar(ActualCompany)
    },[ActualCompany])

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href="/#"
          ref={ref}
          className="mnp-name"
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
          <em className="open" style={{fontSize:"14px", marginLeft:"10px"}}></em>
        </a>
      ));

    return (
        <aside className='aside-container'>
            <ChangePassword showModal={changePassword} handleCloseModal={handleCloseModal}/>
            {loading && (
                <Loading loading={loading} addClass="heigth100vh"/>
            )}
            <div className={`profile-wrap text-left ${(loading ? "hidden" : "")}`}>      
                    <div className="pad-btm">
                        <img alt="Foto do usuário" className="img-circle img-md" src={urlPhoto}/>
                    </div>
                <div className="profile-desc">
                    <Dropdown title={userName}>
                        <Dropdown.Toggle as={CustomToggle} >
                            {userName.toString().substring(0,25) +  (userName.toString().length > 25 ? '...' : '')}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={e => setChangePassword(true)}><em className="fa fa-user-lock"></em> Mudar senha</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={e => (window.addTab(e, 'Meu Perfil', 'fa fa-user-cog', '/profile',{},0,true))}><em className="fa fa-user-cog"></em> Meu Perfil</Dropdown.Item> 
                            <Dropdown.Divider />
                            <Dropdown.Item href="#" onClick={e => (window.addTab(e, 'Minhas Faturas', 'fa fa-user-cog', '/bill',{},0,true))}><em className="fa fa-file-invoice-dollar"></em> Minhas Faturas</Dropdown.Item> 
                        </Dropdown.Menu>
                    </Dropdown>
                    <span className="mnp-desc">{occupation}</span>
                </div>
                <span style={{fontSize:"10px", color:"#fff", marginTop:"10px"}}>Versão Software: {process.env.REACT_APP_VERSION}</span>                                      
                <span style={{fontSize:"10px", color:"#fff"}}>Versão Base: {localDatabase}</span>   
            </div>
            <div className="aside-inner" id="page-sidebar-wrapper">
                <nav data-sidebar-anyclick-close="" className="sidebar custom-scrollbar">    
                    <ul className="sidebar-nav">
                            {
                                menu.map((item, i) => {
                                    // heading
                                    if(itemType(item) === 'heading')
                                        return (
                                            <SidebarItemHeader item={item} key={i} />
                                        )
                                    else {
                                        if(itemType(item) === 'menu')
                                            
                                            return (
                                                <SidebarItem isActive={routeActive(item.path)} item={item}  key={i} collapse={true}/>
                                            )
                                        if(itemType(item) === 'submenu')
                                    
                            
                                            return [
                                                <SidebarSubItem idPlan={idPlan} item={item} isOpen={collapse['_collapse'][item.name]} handler={ toggleItemCollapse.bind(this, item.name) } isActive={routeActive(getSubRoutes(item))} key={i}>
                                                    <SidebarSubHeader item={item} key={i}/>
                                                    {
                                                    
                                                       idPlan > 0 && item.submenu.map((subitem, i) => {
                                                                let _subitem = subitem
                                                                _subitem.path = !item.idmodule.includes(idPlan) ? '/payment' : _subitem.path
                                                                return <SidebarItem key={i} item={_subitem} isActive={routeActive(_subitem.path)} collapse={false}/>
                                                            }
                                                        )
                                                    }
                                                </SidebarSubItem>
                                            ]
                                    }
                                    return null; // unrecognized item
                                })
                                
                            }
                            {SidebarRun(closeSidebar)  }
                    </ul>
                </nav>
            </div>

        </aside>
    );
}


