import { memo, useCallback, useState, useEffect } from "react";
import { Handle, useReactFlow, Position } from "reactflow";
import ModalButtonNode from "./../../../pages/modals/workflow/modal-button-node";
import { messageCustom } from "./../../Utils"

const ButtonNodeItem = ({ handleId, value, index }) => {
    return (
        <div className="custom-node__options" style={{minWidth:"170px"}} title={value}>
          <li className="list-item d-flex align-items-center" style={{minWidth:"170px"}}>
            {index === undefined && (
                <div className="list-marker me-1">
                <i className="fa-solid fa-circle-check"></i>
                </div>
            )}
            <div style={{textOverflow: "ellipsis", maxWidth:"170px",whiteSpace: "nowrap",overflow: "hidden" }} >{(index !== undefined) && (<><span style={{fontWeight:"bold"}}>{index}</span> <span>-</span></>)} {value}</div>
          </li>
          <Handle type="source" position={Position.Right} id={`node-button${handleId}`}/>
        </div>
      );
};

const ButtonNode = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [buttons, setButtons]     = useState([])
    const [title, setTitle]         = useState('')
    const [id, setID]               = useState(-1)
    const [statistic, setStatistic] = useState(0)

    const reactFlowInstance         = useReactFlow();

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setButtons(nodeInfo.buttons);
            setTitle(nodeInfo.title)
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
        };
        handleNodeProps();
    }, [props.data]);
  
    const constas = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);


    const handleCloseModal = () => {
        setShowModal(false);
    };

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node             : _id,
                    parentId         : -1, 
                    targetId         : -1,
                    body             : props.data.nodeInfo.body,
                    footer           : props.data.nodeInfo.footer,
                    title            : props.data.nodeInfo.title,
                    buttons          : props.data.nodeInfo.buttons,
                    redirectLead     : props.data.nodeInfo.redirectLead,
                    closeInteraction : props.data.nodeInfo.closeInteraction,
                    objectType       : 5 
                }
            }
        }))
    }


    return (
        <>
            <ModalButtonNode
                nodeId={props.id}
                nodeData={props.data.nodeInfo}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
            ></ModalButtonNode>

            <div className="rfnode rfnode-button d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow"  onClick={(e) => setShowModal(true)}>
                <div className="rfnode-title">#{id} Botões</div>
                <div  style={{ maxWidth: "200px", borderRadius: "5px", padding: "4px" }}>
                    {title !== '' && (
                        <div style={{padding:"5px", borderRadius:"5px", border:"1px dashed #cacaca", display:"flex"}}>
                            <span style={{fontSize:"10px",textAlign:"left", textWrap:"wrap"}} dangerouslySetInnerHTML={{__html: messageCustom(title,'')}}></span>

                        </div>
                    )}
                    {buttons.length > 0 ?
                        <div style={{padding:"5px"}}>
                            {buttons.map((item,optionId) => {
                                if (!item?.isRedirect) {
                                    return (
                                        <div style={{background:"#fd7e141c", borderRadius:"5px", padding:"5px", display:"flex", height:"27px", marginTop:"2px" ,fontSize:"8px", alignItems:"center"}}>
                                            <ButtonNodeItem
                                                index={optionId}
                                                key={optionId + '-' + optionId + 4}
                                                value={item.body}
                                                handleId={optionId + 4}
                                            ></ButtonNodeItem>
                                        </div>
                                    )   
                                    }
                            })} 

                            <div style={{marginTop:"15px"}}>
                                <div style={{background:"#fd7e147c", borderRadius:"5px", padding:"5px", display:"flex", height:"27px", marginTop:"2px" ,fontSize:"8px", alignItems:"center"}}>
                                    <ButtonNodeItem value={buttons.filter(_item => _item?.isRedirect === true).length > 0 ? buttons.filter(_item => _item?.isRedirect === true)[0].body  : "Recrie novamente o objeto"} 
                                                    handleId={buttons.filter(_item => _item?.isRedirect === true).length > 0 ? buttons.filter(_item => _item?.isRedirect === true)[0].id: -10}></ButtonNodeItem>
                                </div>
                            </div>
                        </div>              
                    : 
                        <div style={{ padding:"10px", background:"rgba(253, 126, 20, 0.29)", borderRadius:"5px", margin:"5px 5px", color:"#fd7e14", display:"flex",alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <i className="fa fa fa-mobile-screen"/>
                            </div>
                            <span style={{fontSize:"6px"}}>Aqui você adiciona os botões </span>
                        </div>
                    }
                    <div style={{display:"flex", fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #fd7e14"}}>
                        <div style={{display:"flex", gap:"5px"}}>
                            <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                                <i className="fa fa-copy text-primary"></i>
                            </div>
                            <div onClick={() => constas()} title="Excluir Mensagem" className="workflow-delete" >
                                <i className="fa fa-trash text-danger"></i>
                            </div>
                        </div>
                        <div title="Número de vezes que o lead passou pela ação">
                            <span style={{fontSize:"10px", color:"#fd7e14"}}>{statistic || ''}</span>
                        </div>

                    </div>
                </div>
            </div>
            <Handle type="target" position={Position.Bottom} id='node-button1' />
            <Handle type="target" position={Position.Top}    id='node-button2' />
            <Handle type="target" position={Position.Left}   id='node-button3' />
        </>
  );
};

export default memo(ButtonNode);
