import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import { TextInput, ValidationForm } from "react-bootstrap4-form-validation";
import loadingButton from '../layouts/Buttons'
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import { Button } from 'reactstrap';
import Loading from '../../components/Loading';

export default function TemplateEmail({ props, id }) {
    const [content, setContent] = useState('')
    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState('')
    const [url, setURL] = useState('')

    useEffect(() => {
        if (props.idemailtemplate) {
            setLoading(true)

            const getData = async () => {
                await api.get('/email-template/' + props.idemailtemplate)
                    .then(response => {
                        let _data = response.data
                        setDescription(_data.description)
                        setURL(_data.url || '')
                        setContent(_data.content)
                        setLoading(false)
                    })
                    .catch(error => {
                        ShowMessages(error)
                    })
            }

            getData()
        }
        setStatistic('maintenance\\template-email')
    }, [])

    const handleSubmit = async (event) => {
        loadingButton(true, document.querySelector('#submit-template-email'), document.querySelector('#submit-template-email').children[0].classList, document.querySelector('#submit-template-email').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {
                content,
                description,
                url
            }

            if (props.editState) {
                await api.put('/email-template/' + props.idemailtemplate, _data)
                    .then(response => {
                        ShowMessages(response)
                        if (response.ok) {
                            if (response.data.status === 200) {

                            } else
                                loadingButton(false, document.querySelector('#submit-template-email'))
                        } else
                            loadingButton(false, document.querySelector('#submit-template-email'))
                        window.removeTab(`tab_${id}`, event)
                    })
                    .catch(error => {
                        loadingButton(false, document.querySelector('#submit-template-email'))
                        ShowMessages(error)
                    })
            } else {
                await api.post('/email-template/', _data)
                    .then(response => {
                        ShowMessages(response)
                        if (response.ok) {
                            if (response.data.status === 200) {

                            } else
                                loadingButton(false, document.querySelector('#submit-template-email'))
                        } else
                            loadingButton(false, document.querySelector('#submit-template-email'))
                        window.removeTab(`tab_${id}`, event)
                    }).catch(error => {
                        loadingButton(false, document.querySelector('#submit-template-email'))
                        ShowMessages(error)
                    })
            }

        } catch (response) {
            loadingButton(false, document.querySelector('#submit-template-email'))
            ShowMessages(response)
        }
    }

    return (
        <ContentWrapper>
            {loading && (
                <Loading loading={loading} addClass="heigth330px" />
            )}
            <div className={`${loading ? "hidden" : ""}`}>
                <ValidationForm className="modal-content" setFocusOnError={true}>
                    <div style={{ marginBottom: "15px", display: "flex", gap: "5px" }}>
                        <div style={{ width: "300px" }}>
                            <label>Descrição do Template</label>
                            <TextInput type="text" className='form-control' required value={description} onChange={e => setDescription(e.target.value)} errorMessage={{ required: "Por favor, informe uma descrição!" }} maxLength={150} />
                        </div>
                        <div style={{ width: "300px" }}>
                            <label>Link</label><small style={{ color: "red", marginLeft: "5px" }}>(Usar a tag <span style={{ fontWeight: "bold" }}>{"{URL}"}</span> para ser substituido pela URL)</small>
                            <TextInput type="text" className='form-control' value={url} onChange={e => setURL(e.target.value)} maxLength={1000} />
                        </div>
                        <div style={{display:"flex", flexDirection:"column", width:"250px", borderRadius:"5px", padding:"5px", background:"#ffc107"}}>
                            <span><span style={{fontWeight:"bold", marginRight:"7px"}}>{"{lead}"}</span> Altera pelo nome do lead</span>
                            <span><span style={{fontWeight:"bold", marginRight:"20px"}}>{"{id}"}</span> Altera pelo id do template</span>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <div className='box-shadow' style={{ width: "50%", height: "70vh", gap: "5px", display: "flex", flexDirection: "column", background: "#f7f8f9" }}>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <i className='fa fa-code' style={{ fontSize: "22px" }} />
                                    <span>Código HTML</span>
                                </div>
                                <textarea style={{ height: "100%" }} className='form-control' value={content} spellCheck="false" onChange={e => setContent(e.target.value)} />
                            </div>
                            <div style={{ width: "50%" }} className='box-shadow'>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <i className='fa fa-eye' style={{ fontSize: "22px" }} />
                                    <span>Visualização</span>
                                </div>
                                <div style={{ height: "65vh", overflow: "auto" }}
                                    dangerouslySetInnerHTML={{ __html: content }}
                                    className="text-black img-default"
                                />
                            </div>
                        </div>
                        <div className="modal-footer footer-fixed modal-footer-bottom-10" style={{ gap: "5px" }}>
                            <Button type="submit" id='submit-template-email' className={`btn-theme ${loading ? "hidden" : ""}`} onClick={e => handleSubmit(e)}>
                                <em className="fa fa-save"></em> Salvar
                            </Button>
                            <Button className="btn-ligth-gray" onClick={e => window.removeTab(`tab_${props.id}`, e)}>
                                <em className="fa fa-door-closed"></em> Fechar
                            </Button>
                        </div>
                    </div>
                </ValidationForm>
            </div>

        </ContentWrapper>
    )
}