import React, { useState, useEffect, useRef } from 'react'
import Loading from '../Loading';
import { messageCustom, RepeatRemove, PopulateConversation , getDataNameAndHour, convertToBase64, phone_format} from "./../../components/Utils";
import ShowMessages from './../Exceptions'
import { setCookieMessage, getCookieMessage } from './../Cookies';
import Swal from 'sweetalert2'
import {setDataDB, getDataDB, lastID, setID } from '../../services/idb'
import { USER_DATA, ReadDataStorage, IDCOMPANY} from './../../services/auth'
import { useChatData } from './chatContext'
import USER from './../../assets/user-gray.png'
import api from '../../services/api'
import {subHours,isEqual  } from 'date-fns'
import {Dropdown} from 'react-bootstrap'
import ModalSelectedNumber from './../../pages/modals/modal-selected-number'

export default function MessageList({ props }) {
    const [showModal, setShowModal]                     = useState(false)
    const [loading, setLoading]                         = useState(false)
    const [loadingLeads, setLoadingLeads]               = useState(false)
    const [loadingInfinite, setLoadingInfinite]         = useState(false)
    const [isRecording, setIsRecording]                 = useState(false)
    const [newChat, setNewChat]                         = useState(false)  
    const [leads, setLeads]                             = useState([])
    const [leadsAll, setLeadsAll]                       = useState([])
    const [textSearch, setTextSearch]                   = useState('')
    const [dataTag, setDataTag]                         = useState([])
    const [page, setPage]                               = useState(1)
    const [lastPage, setLastPage]                       = useState(1)
    const [numberSettings, setNumberSettings]           = useState([])
    const [filterTag, setFilterTag]                     = useState(0)
    
    const [infiniteScroll, setInfiniteScroll]          = useState(false)

    const [showContextMenu, setShowContextMenu]         = useState(false)
    const [position, setPosition]                       = useState({})
    const [propsShowChat, setPropsShowChat]             = useState({})
    const containerRef                                  = useRef(null)

    const {  setStartHuman, setNumber,setIdAttendant,setIsNotRead,
        setEndHuman, setIsOpened, settings, setTo,setUserNumber,
    chatList, setChatList, setConversation, setNameUser, setIsUnanswered,
    setPhotoUser, setIDPeople, setLoadingChat,isNotRead, isUnanswered,
    setIdAutomationChatHeadHistory, setUnread, handleReload,setFilename,
    setIsGroup, setShowPreview, showPreview, setSelectedFiles, setMessageType} = useChatData()

    const _div = containerRef.current
    const loadingChatList = async (action, id, newPage) => {
        // 1 - isNotRead
        // 2 - isUnanswered
        // 3 - onlytag
        let _url
        setLoadingInfinite(true)
        setInfiniteScroll(true)
        if (newPage === 0)
            setLoading(true)
        switch (action) {
            case 0 : _url = `/getChatList/${page}`
                setIsNotRead(false)
                setIsUnanswered(false)
                setFilterTag(0)
                break;
            case 1 : _url = isNotRead ? `/getChatList/${page}` : `/getChatListNotRead/${page}`
                     setIsNotRead(!isNotRead)
                break;
            case 2 : _url = isUnanswered ? `/getChatList/${page}` : `/getChatListUnanswered/${page}`
                     setIsUnanswered(!isUnanswered)
                break;
            case 3 : _url = id > 0 ? `/getChatTag/${page}/` + id : `/getChatList/${page}`
                setFilterTag(id)
                break
            default:
                break;
        }

        
        await api.get(_url)
                 .then(async (response) => { 
                    let _unread = response.data.data.reduce((_total,_item) => {
                        return _total + (_item.unread === 9999999 ? 0 : _item.unread)
                    },0)

                    setLastPage(response.data.lastPage)
                    //GRAVA MENSAGENS NO IDB E SETA NA USESTATE
                    if (response.data.data.length > 0) {
                        if (page > 1) {
                            let _header = [...(await getDataDB('HEADER',ReadDataStorage(IDCOMPANY)) || []),...response.data.data]
                            _header.sort((a, b) => new Date(b.update_at) - new Date(a.update_at));
                            setDataDB('HEADER',_header,handleReload,ReadDataStorage(IDCOMPANY))
                        } else {
                            setDataDB('HEADER',response.data.data,handleReload,ReadDataStorage(IDCOMPANY))
                        }
                        setChatList(response.data.data)
                        setUnread(_unread)
                    }

                    setLoading(false)
                    setLoadingInfinite(false)
                }).catch(error => {
                    setLoading(false)
                    ShowMessages(error)
                }) 
    }
    
    const showChat = async(notifyName,photo,to,_startHuman,_endHuman,idautomationchatheadhistory,idpeople,idAttendant,number,_numberSettings,isGroup) => {
        let _number    = number
        setLoadingChat(true)
        const _getConversation = async(lastID,number) => {
            await api.post('/getConversation/1', {lastID, 
                                                  number : (number || '').toString().replace(/[\W_,[a-z]+/g,""),
                                                  to : to.toString().replace(/[\W_,[a-z]+/g,""),
                                                  isNotRead,
                                                  isUnanswered,
                                                  filterTag
                       }).then(async(response) => { 
                            let rConversation = response.data.conversation
                            let _msg  = []

                            rConversation.map(_item => {
                                let _populate = PopulateConversation(_item,_item.isreceive)
                                _msg.push(_populate)
                            })

                            let _conversationDB = [..._msg || [],...(await getDataDB(_keyNumber,ReadDataStorage(IDCOMPANY)) || [])] 
                            _conversationDB = RepeatRemove(_conversationDB)

                            response.data.dataUpdate.map(_item => {
                                let _conversation = _conversationDB.find(_filter => {
                                    return _filter.idmessage === _item.idmessage
                                })
                                if (_conversation)
                                    _conversation.ack = _item.ack
                            })         

                            _conversationDB.sort((a, b) => {
                                if (isEqual(new Date(a.create_at) , new Date(b.create_at))) 
                                    return new Date(a.idmessagehistory) - new Date(b.idmessagehistory);
                                else
                                    return new Date(a.create_at) - new Date(b.create_at);
                            })

                            setConversation(_conversationDB)
                            setDataDB(_keyNumber,_conversationDB,null,ReadDataStorage(IDCOMPANY))
                            let _count = response.data.data?.conversation.length

                            //SETA O ULTIMO ID DE MENSAGEM O IDB
                            if (_count > 0)
                                setID(_keyNumber,response.data?.conversation[_count-1].idmessagehistory,ReadDataStorage(IDCOMPANY))

                            let _unread = response.data?.chatList.data.reduce((_total,_item) => {
                                return _total + (_item.unread === 9999999 ? 0 : _item.unread)
                            },0)

                            setUnread(_unread)
                            setChatList(response.data?.chatList.data)
                            setNameUser(notifyName || response.data?.header?.notifyname)
                            setPhotoUser(photo || response.data?.header?.photo)
                            if (idpeople === 0)
                                setIDPeople(response.data?.header?.idpeople)
                            setCookieMessage('idchat',parseInt(response.data?.header?.idmessagehistoryheader))
                            setNumber(response.data?.header?.number || number)
                            _number = response.data?.header?.number || number
                            setTo(response.data?.header?.to || to)

                       }).catch(error => {
                            ShowMessages(error)
                       }) 
        }

        let _checkNewChat = chatList.filter(_item => {
            return ((_item.to.toString() === (to.length <= 11 ? '55' : '') + to.toString()) && (_item.original_number.toString() === number.toString()))
        })

        let _keyNumber = number + '_' + to.toString().replace(/[\W_,[a-z]+/g,"")
        let _lastID    = (await lastID(_keyNumber,ReadDataStorage(IDCOMPANY))) || 0
        

        setNameUser(notifyName)
        setPhotoUser(photo)
        setIDPeople(idpeople)
        setTo(to)

        if (_number){
            await _getConversation(_lastID,_number)
        } else if ((_checkNewChat.length === 0) && (!_number)){
            await _getConversation(_lastID,null)

            if (!_number) {
                let _numbers = (_numberSettings || numberSettings).filter(_item => _item.idnumberstatus === 1)
                if (_numbers.length <= 0) {
                    ShowMessages({status : 500, message : 'Nenhum número está conectado no momento.'})
                    return
                } else if (_numbers.length === 1) {
                    _keyNumber = number + '_' + to.toString().replace(/[\W_,[a-z]+/g,"")
                    _lastID    = (await lastID(_keyNumber,ReadDataStorage(IDCOMPANY))) || 0
                    await _getConversation(_lastID,_numbers[0].number)
                } else {
                    setPropsShowChat({
                        notifyName,photo,to,_startHuman,_endHuman,idautomationchatheadhistory,idpeople,idAttendant,_numbers,_numberSettings,isGroup
                    })
                    setShowModal(true)
                        return
                }
            }
        }
       
        setUserNumber(_number)
        setNumber( _number)
        setIsOpened(_startHuman !== null && _endHuman === null)
        setStartHuman(_startHuman)
        setIsGroup(isGroup)
        setEndHuman(_endHuman)
        setIdAutomationChatHeadHistory(idautomationchatheadhistory)
        setShowPreview(false)
        setNewChat(false)
        setIdAttendant(idAttendant)       
        setLoadingChat(false)
    }

    window.showChat = showChat

    const handleFilter = async (text) => {
        setTextSearch(text)
        if (newChat) {
            let _leads
            if (text !== '') {
                _leads = leadsAll.filter(_item => {
                    return (_item.phone.toString().replace(/[\W_,[a-z]+/g,"").toUpperCase().indexOf(text.toString().replace(/[\W_,[a-z]+/g,"").toUpperCase()) >= 0) || 
                           (_item.name.toString().toUpperCase().indexOf(text.toString().toUpperCase()) >= 0)
                })
            } else 
                _leads = leadsAll
            setLeads(_leads)
        } else {
            let _chatList = await getDataDB('HEADER',ReadDataStorage(IDCOMPANY))
            if (text !== '') {
                _chatList = _chatList.filter(_item => {
                    return ((_item.notifyname || '').toString().toUpperCase().indexOf(text.toString().toUpperCase()) >= 0) || 
                            ((_item.to || '').toString().toUpperCase().indexOf(text.toString().toUpperCase()) >= 0)
                })
                setChatList(_chatList)
            } else 
                setChatList(_chatList)
        }
    }

    const escExit = (e) => {
        if (e.keyCode === 27) {
            if (showPreview) {
                setShowPreview(false)
            }
        }
    }

    const getNumberSettings = async() => {
        return await api.get('/getPermissionNumbers')
                        .then((response) => {
                            setNumberSettings(response.data)
                            return response.data
                        }).catch((error) => {
                            ShowMessages(error)
                        })           
    }

    const handleNewChat = async() => {
        setLoadingLeads(true)
        setNewChat(!newChat)
        if (!newChat)
            await api.get('/getClient')
                     .then((response) => {
                        setLeads(response.data)
                        setLeadsAll(response.data)
                        setLoadingLeads(false)
                     }).catch((error) => {
                        ShowMessages(error)
                     })
                   
    }

    const toMap = (text) => {
        if (text) {
            let _tag = typeof text === 'object' ? text : JSON.parse(text)
            let _return = []
            _tag.map(_i => {
                let _filter = dataTag.filter(_item => {
                    return parseInt(_item.idtag) === parseInt(_i.tag)
                })

                if (_filter.length > 0)
                    _return.push({
                        text  : _filter[0].tag,
                        color : _filter[0].color
                    })
            })
            return _return
        } else {
            return []
        }
    }

    const deleteMessage = (id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir todo o histório de conversa?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/history/' + id) 
                         .then((response) => {  
                            ShowMessages(response)
                            props.setUpdate()
                        }).catch(error => {
                            ShowMessages(error)
                        })  
            }
            
          })
    }

    const pinnedMessage = async(e,id) => {
        
        //ATUALIZAÇÃO FEITA POR FORA DA REQUISIÇÃO PARA GANHAR TEMPO
        let _chatList = chatList.find(_item => {
            return _item.idmessagehistoryheader === id

        })
        _chatList.pinned = !_chatList.pinned
        
        chatList.sort((a, b) => {
            if (a.pinned !== b.pinned) 
                return b.pinned - a.pinned;
            return new Date(b.update_at) - new Date(a.update_at);
        })

       // e.stopPropagation()
        await api.post('/history/pinned/',{
            id
        }).then((response) => {
            //
        }).catch((error) => {
           ShowMessages(error)
        })
    }
    
    const getColor = (number) => {

        if ((number === '') || (number === undefined))
            return '#ffc107'

        let _data = numberSettings.filter(_item => _item.number === number.toString().replace(/[\W_,[a-z]+/g,''))

        if (_data.length > 0)
            return _data[0].numbercolor
        else
            return '#ffc107'
    }

    const CountUp = ({initialDate}) => {
        const [timeLeft, setTimeLeft] = useState(getTimeLeft(initialDate));

        function getTimeLeft(startDate) {
            const now = new Date()

            const difference = now.getTime() - subHours(new Date(startDate),-3).getTime();
      
            const hours = Math.floor(difference / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      
            return {
                hours,
                minutes,
                seconds
            };
        }
      
        useEffect(() => {
            const timer = setInterval(() => {
                setTimeLeft(getTimeLeft(initialDate));
            }, 1000);
      
            return () => clearInterval(timer);
        }, [initialDate]);
      
        return (
            <div className='bg alert alert-primary' style={{padding:"1px 5px", borderRadius:"5px", display:"flex", justifyContent:"center",alignItems:"center", marginBottom:"3px"}}>
                <i className='fa fa-headset' style={{fontSize:"10px"}}/>
                <span style={{fontSize:"10px", marginLeft:"5px"}}>{(timeLeft.hours.toString().length === 1 ? '0' : '') + timeLeft.hours}:{(timeLeft.minutes.toString().length === 1 ? '0' : '') + timeLeft.minutes}:{(timeLeft.seconds.toString().length === 1 ? '0' : '') + timeLeft.seconds}</span>
            </div>
        );
    }
  
    const handleCloseModal = () => { setShowModal(false)}

    const handleContextMenu = (e,id) => {
        e.preventDefault()
        setPosition({x : e.pageX, y: e.pageY, id})
        setShowContextMenu(true)
    }

    const handleUnread = async(e) => {
        e.preventDefault()
 
        setShowContextMenu(false)
        let _set = chatList.find(_item => {
            return parseInt(_item.idmessagehistoryheader) === parseInt(position.id)
        })

        if (_set)
            _set.unread = 9999999

        await api.post('/history/unread/',{
            id : position.id
        }).then((response) => {
            //
        }).catch((error) => {
           ShowMessages(error)
        })
    }

    const ElementContextMenu = () => {
        return (
            <>
                {showContextMenu && (
                    <div className={`box-shadow context-menu ${showContextMenu ? 'show' : ''}`} style={{background:"#fff", top:`${position.y - 100}px`, left:`${position.x-230}px`,width:"200px", height:"50px", zIndex:"99999", position:"absolute"}} onMouseLeave={e => setShowContextMenu(false)}>
                        <div className='box-context' onClick={e => handleUnread(e)}>
                            <div>
                                <i className='fa fa-list-check'/>
                            </div>
                            
                            <div>
                                <span>Marcar como não lida</span>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }

    useEffect(() => {
        const showData = async() => {
            let _numberSettings = await getNumberSettings()
            showChat('','',props.to,null,null,0,0,0,null,_numberSettings,false) 
        }
        if (props?.newchat)
            showData()
    },[props])

    useEffect(() => {
        const getTags = async() => {
            await api.get('/getAnnotationTags')
                     .then((response) => {
                        setDataTag(response.data)
                     })
                     .catch((error) => {
                        ShowMessages(error)
                     })  
            
        }
        getNumberSettings()
        getTags()
        document.addEventListener('keydown', escExit);
    },[])

    useEffect(() => {
        const handleDragOver = (e) => {
            e.preventDefault();
        }

        const handleDrop = async(e) => {
            e.preventDefault();

            e.target.closest('.box-chat').click()
            let _files  = e.target.files || e.dataTransfer.files
            let _length = e.target.files?.length || e.dataTransfer.files.length

            if (_files.length > 0) {
                let files  = [];

                for (let i = 0; i < _length; i++) {
                    if (_files[i].size > 41943040) {
                        ShowMessages({status : 500, message : "O arquivo informado não pode ser maior que 40Mb"})
                    } else {
                        files.push({
                            src        : URL.createObjectURL(_files[i]),
                            altText    : _files[i].name,
                            file       : URL.createObjectURL(_files[i]),
                            name       : _files[i].name,
                            size       : _files[i].size,
                            type       : _files[i].type,
                            base64     : await convertToBase64(_files[i]),
                            attendance : settings.showAttendenceName ? JSON.parse(ReadDataStorage(USER_DATA)).name : ''
                        })
                        setFilename(files[0])
                        setMessageType(2)
                }
            }

            setSelectedFiles(files);
        }

        setShowPreview(true)
        };

        [...document.querySelectorAll('.box-chat')].map(_item => {
            _item.addEventListener('dragover', handleDragOver);
            _item.addEventListener('drop', handleDrop);
        })


        return () => {
            [...document.querySelectorAll('.box-chat')].map(_item => {
                _item.removeEventListener('dragover', handleDragOver);
                _item.removeEventListener('drop', handleDrop);
            })
          };
    }, [chatList])

    useEffect(() => {
        const handleScroll = () => {
            if ((_div.offsetHeight + _div.scrollTop === _div.scrollHeight)){ 
                if ((page < lastPage) || (page === 1)) {
                    setPage((prevPage) => prevPage + 1)
                }
            } else if ((_div.offsetHeight + _div.scrollTop) < _div.scrollHeight)
                setInfiniteScroll(false)
        };
      
        if (_div)
            _div.addEventListener('scroll', handleScroll);
        return () => {
            if (_div)
                _div.removeEventListener('scroll', handleScroll);
        }
    },[_div, page])

    useEffect(() => {
        if (!infiniteScroll) 
            loadingChatList(isNotRead ? 1 : isUnanswered ? 0 : filterTag > 0 ? 0 : 0,filterTag,page)
    },[page])


    return (
        <div>
            {loading && (
                <Loading loading={loading} /> 
            )}
            <ModalSelectedNumber showModal={showModal} handleCloseModal={handleCloseModal} props={propsShowChat} showChat={showChat}/>
            <ElementContextMenu/>
            <div className={`content-box-chat ${(loading ? "hidden" : "")}`}>       
                <div style={{width:"300px"}}>
                    <div style={{display:"flex", padding:"8px", justifyContent:"space-between", gap:"5px", background:"#f7f8f9"}}>
                        <div style={{width:"100%"}}>
                            <input className='form-control' value={textSearch} placeholder='Digite para pesquisar...' onChange={e => handleFilter(e.target.value)}/>
                        </div>
                        <div style={{display:"flex", flexDirection:"column"}} title="Filtros">
                            <Dropdown title='Opções' variant="success" style={{textAlign:"right", display:"flex", marginTop:"-1px", padding:"2px"}} onClick={e => e.stopPropagation()}>
                                <Dropdown.Toggle  style={{padding:"7px 10px"}} variant="transparent" className='btn-transparent-chatbot' >
                                    <em className='fa fa-bars'></em>      
                                </Dropdown.Toggle>
                                {filterTag > 0 || isUnanswered || isNotRead ?  (
                                    <Dropdown.Menu style={{padding:"0px"}} >
                                        <Dropdown.Item href="#" >
                                            <div style={{display:"flex", gap:"5px", alignItems:"center"}} onClick={e => loadingChatList(0,0,0)}>
                                                <em className="fa fa-bell"></em>  
                                                <span>Todas as Conversas</span>
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                ) : (
                                    <Dropdown.Menu style={{padding:"0px"}} >
                                        {!isUnanswered && (
                                            <Dropdown.Item href="#" >
                                                <div style={{display:"flex", gap:"5px", alignItems:"center"}} onClick={e => loadingChatList(1,0,0)}>
                                                    <em className="fa fa-bell"></em>  
                                                    <span>Não Lidos</span>
                                                </div>
                                            </Dropdown.Item>
                                        )}

                                        {!isNotRead && (
                                            <Dropdown.Item href="#" >
                                                <div style={{display:"flex", gap:"5px", alignItems:"center"}} onClick={e => loadingChatList(2,0,0)}>
                                                    <em className="fa fa-comment-dots"></em>  
                                                    <span>Não Respondidos</span>
                                                </div>
                                            </Dropdown.Item>    
                                        )}

                                        {dataTag.length > 0 && (
                                            <>
                                                <Dropdown.Divider/>
                                                <Dropdown.ItemText>Etiquetas</Dropdown.ItemText>
                                                {dataTag.map(_item => {
                                                    return (
                                                        <Dropdown.Item href="#" >
                                                            <div style={{display:"flex", gap:"5px", alignItems:"center",}} onClick={e => loadingChatList(3,_item.idtag,0)}>  
                                                                <div>
                                                                    <i className='fa fa-bookmark' style={{color:`${_item.color}`}}/>
                                                                </div>
                                                                <span>{_item.tag}</span>
                                                            </div>
                                                        </Dropdown.Item> 
                                                    )
                                                })}
                                            </>
    
                                        )}
                                    </Dropdown.Menu>
                                )}
                            </Dropdown>                          
                        </div>
                    </div>

                    {((newChat) && ([0,1,3,4].includes(settings.modeType))) ? (
                        <div className="box-leads">
                            {loading && (
                                <Loading loading={loadingLeads} />
                            )}
                            <div className={`${loadingLeads ? 'hidden' : ''}`}>
                                {(leads || []).map((_data,id) => {
                                    return (
                                        <div key={id} className="box-lead" onClick={e => showChat(
                                                                                         _data.name || _data.phone_format,
                                                                                         '',
                                                                                         _data.phone.toString().replace(/[\W_,[a-z]+/g,''),
                                                                                         null,
                                                                                         null,
                                                                                         0,
                                                                                         _data.idpeople,
                                                                                         0,
                                                                                         null,
                                                                                         false)}>
                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                                <img src={USER} alt="" style={{width:"40px"}}/>
                                            </div>
                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                                <span style={{fontWeight:"bold"}}>{_data.name}</span>
                                                <span>{_data.phone_format}</span>
                                            </div>
                                        </div>
                                    )
                                })}

                                {((leads.length === 0) && (textSearch.length >= 10))&& (
                                    <div className="box-lead" onClick={e => showChat(
                                                                                     textSearch,//notifyName
                                                                                     '',//photo
                                                                                     textSearch.toString().replace(/[\W_,[a-z]+/g,""),//to
                                                                                     null,//_startHuman
                                                                                     null,//_endHuman
                                                                                     0,//idautomationchatheadhistory
                                                                                     0,//idpeople
                                                                                     0,//idAttendant
                                                                                     null,//number
                                                                                     false)}>
                                                                       
                                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                            <i className='fa fa-circle-plus' style={{fontSize:"32px"}}/>
                                        </div>
                                        <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                            <span style={{fontWeight:"bold"}}>Novo Contato</span>
                                            <span>{textSearch}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                     ) : (<>
                        <div style={{display:"flex", flexDirection:"column", gap:"3px", height:"85vh", overflowY:"auto", overflowX:"hidden", background:'#f7f8f9', paddingBottom:`${chatList.length > 0 ? '55px' : '0px'}`}}  ref={containerRef}>
                            {(chatList || []).filter(_item => _item !== null).map((_data,id) => {
                                return (
                                    <div id={id} onContextMenu={e => handleContextMenu(e,_data.idmessagehistoryheader)} style={{padding:"0px"}} data-id={_data.idmessagehistoryheader} className={`chat_id_${_data.idmessagehistoryheader} box-shadow box-chat ${_data.idmessagehistoryheader === parseInt(getCookieMessage('idchat')) ? 'box-chat-selected' : ''}`} onClick={e => showChat(
                                                                                                                                                                                                _data.notifyname || _data.to,
                                                                                                                                                                                                _data.photo || USER,
                                                                                                                                                                                                _data.to.toString(),
                                                                                                                                                                                                _data.starthuman,
                                                                                                                                                                                                _data.endhuman,
                                                                                                                                                                                                _data.idautomationchatheadhistory,
                                                                                                                                                                                                0,
                                                                                                                                                                                                _data.idattendant,
                                                                                                                                                                                                _data.original_number.toString().replace(/[\W_,[a-z]+/g,""),
                                                                                                                                                                                                null,
                                                                                                                                                                                                _data.isgroup)}>

                                        <div style={{display:"flex",width:"100%"}}>
                                            <div className={`bg ${_data.idstatusservequeues === 0 ? 'alert alert-warning' : _data.idstatusservequeues === 1 ? 'alert alert-success' : 'alert-primary' }`} style={{width:"5px", borderTopLeftRadius:"5px", borderBottomLeftRadius:"5px"}}>
                                                &nbsp;
                                            </div>
                                            <div style={{width:"100%", padding:"0px 8px 2px 0px"}}>
                                               <div style={{display:"flex", marginLeft:`${JSON.parse(ReadDataStorage(USER_DATA)).user_data.isroot ? '0px' : '75px'}`, justifyContent:`${([0,1,2,3,4,5].includes(settings.modeType)) ? 'space-between' : 'center'}`}}>
                                                    {JSON.parse(ReadDataStorage(USER_DATA)).user_data.isroot && (    
                                                        <div onClick={e => deleteMessage(_data.idmessagehistoryheader)} className='trash-over' title="Excluir chat selecionado">
                                                            <i className='fa fa-trash' style={{color:"#cacaca"}}></i>
                                                        </div>
                                                    )}
                                                    <div className='bg' style={{padding:"5px", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px", background:`${getColor(_data?.original_number)}`}}>
                                                        <i className='fab fa-whatsapp' style={{fontSize:"12px", color:"#000"}}/>
                                                            <span style={{fontSize:"12px", color:"#000", marginLeft:"5px"}}>{_data.number}</span>
                                                    </div>
                      
                                                    {([0,1,2,3,4,5].includes(settings.modeType)) && (
                                                        <div onClick={e => pinnedMessage(e,_data.idmessagehistoryheader)} className='pinner-over' title={_data.pinned ? "Retirar mensagem do topo" : "Fixar a mensagem no topo"}>
                                                            <i className='fa fa-thumbtack' style={{color:`${_data.pinned ?"#28a745" :"#cacaca"}`}}></i>
                                                        </div>
                                                    )}
                                               </div> 
                                               <div style={{display:"flex", marginLeft:"15px", gap:"10px"}}>
                                                    <div>                   
                                                        <div>
                                                            <img style={{width:"50px", height:"50px", borderRadius:"50%"}} onError={(e) => { e.target.src = USER }} src={_data.photo || USER} alt=""/>
                                                        </div>
                                                        <div style={{marginTop:"-20px", marginLeft:"30px"}}>
                                                            <img alt="" style={{width:"20px", height:"20px", borderRadius:"50%"}}src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/WhatsApp_logo-color-vertical.svg/800px-WhatsApp_logo-color-vertical.svg.png"></img>
                                                        </div>  
                                                    </div>
                                                    <div style={{display:"flex", justifyContent:"space-between", width :"100%", marginTop:"10px"}}>
                                                        <div style={{width:"100%"}}>
                                                            <div>
                                                                <span style={{fontWeight:"bold"}}>{_data.notifyname || phone_format(_data.to)}</span>
                                                            </div>
                                                            <div style={{width:"100%",display:"flex", flexWrap:"wrap", justifyContent:`${_data.hasmedia || _data.type === 'location' ? "center" : "flex-start"}`}}>
                                                                {_data.isgroup && (
                                                                    <span style={{fontWeight:"bold",opacity:"70%"}}>{_data.notifyusergroup}:&nbsp;</span>
                                                                )}
                                                                
                                                                {_data.hasmedia ?
                                                                    ((_data.type === 'image') ||  (_data.type === 'sticker')) ? (
                                                                        <div style={{display:"flex",justifyContent:"center"}}>
                                                                            <i className='fa fa-camera' style={{fontSize:"18px", color:"#cacaca", marginLeft:"10px"}}/>
                                                                        </div>
                                                                    ) :  (_data.type === 'video') ? (
                                                                        <div style={{display:"flex",justifyContent:"center"}}>
                                                                            <i className='fa fa-video' style={{fontSize:"18px", color:"#cacaca", marginLeft:"10px"}}/>
                                                                        </div>
                                                                    ) :  (_data.type === 'ptt') ? ( 
                                                                        <div style={{display:"flex",justifyContent:"center"}}>
                                                                            <i className={!isRecording ? 'fa fa-microphone' : 'fa fa-microphone-slash'} style={{fontSize:"18px", color:"#cacaca", marginLeft:"10px"}}/>
                                                                        </div>
                                                                    ) : (_data.type === 'vcard') ? ( 
                                                                        <div style={{display:"flex",justifyContent:"center"}}>
                                                                            <i className='fa fa-address-card' style={{fontSize:"18px", color:"#cacaca", marginLeft:"10px"}}/>
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{display:"flex",justifyContent:"center"}}>
                                                                            <i className='fa fa-file' style={{fontSize:"18px", color:"#cacaca", marginLeft:"10px"}}/>
                                                                        </div>       
                                                                    )
                                                                :
                                                                    <>
                                                                     {(_data.type === 'location') ? ( 
                                                                        <div style={{display:"flex",justifyContent:"center"}}>
                                                                            <i className='fa fa-map-location' style={{fontSize:"18px", color:"#cacaca", marginLeft:"10px"}}/>
                                                                        </div>
                                                                     ) : (
                                                                        <span style={{opacity:"70%"}} dangerouslySetInnerHTML={{__html: messageCustom((_data?.body?.toString().length > 25 ? _data?.body?.toString()?.substr(0,25) + '...' :_data?.body?.toString()) || 'Erro ao buscar msg',_data.original_number.toString().replace(/[\W_,[a-z]+/g,""))}}></span>
                                                                     )}
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{textAlign:"center", lineHeight:"10px", width:"60px"}}>
                                                                <span style={{fontSize:"10px"}}>{getDataNameAndHour(new Date(_data.update_at))}</span>
                                                            </div>
                                                            <div>
                                                                {_data?.unread > 0 && (
                                                                    (_data?.unread === 9999999) ?
                                                                        <div style={{textAlign:"center"}}>
                                                                            <span className='badge badge-success'>&nbsp;&nbsp;</span>
                                                                        </div>
                                                                    :
                                                                        <div style={{textAlign:"center"}}>
                                                                            <span className='badge badge-success'>{_data.unread}</span>
                                                                        </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                               </div> 
                                               <div style={{display:"flex", marginLeft:"5px", gap:"10px", marginTop:"5px"}}>
                                                   <div>
                                                       {(toMap(_data.tags || '[{}]').map((_item,id) =>{
                                                           return (
                                                               <div key={id} style={{padding:"2px 5px", borderRadius:"5px", background:`${_item.color}`}}>
                                                                   <i className='fa fa-tag' style={{fontSize:"10px"}}/>
                                                                   <span style={{fontSize:"10px"}}> {_item.text}</span>
                                                              </div>
                                                           )
                                                       }))}
                                                   </div>
                                                   {(_data.starthuman !== null) && (_data.endhuman === null) && (
                                                        <>
                                                            <div>
                                                                <div style={{height:"100%", display:"flex", alignItems:"end"}}>
                                                                    <CountUp initialDate={_data.starthuman} />
                                                                </div>
                                                            </div>
                                                            <div style={{display:"flex"}}>
                                                                <span style={{fontSize:"10px"}} className='text-success'>{_data.name}</span>
                                                            </div>
                                                        </>
                                                    )}
                                               </div>
                                            </div>
                                        </div>                    
                                    </div>
                                )
                            })}

                            {((chatList.length === 0) && (textSearch.length >= 10))&& (
                                <div className="box-lead" onClick={e =>showChat(
                                                                                textSearch,//notifyName
                                                                                '',//photo
                                                                                textSearch.toString().replace(/[\W_,[a-z]+/g,""),//to
                                                                                null,//_startHuman
                                                                                null,//_endHuman
                                                                                0,//idautomationchatheadhistory
                                                                                0,//idpeople
                                                                                0,//idAttendant
                                                                                null,//number
                                                                                false)}>
                                                                                             
                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                        <i className='fa fa-circle-plus' style={{fontSize:"32px"}}/>
                                    </div>
                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                        <span style={{fontWeight:"bold"}}>Novo Contato</span>
                                        <span>{textSearch}</span>
                                    </div>
                                </div>
                            )}
                            {(chatList.length === 0) && (textSearch.length < 10) && (
                                <div style={{display:"flex",alignItems:"center",justifyContent:"center", flex:"1", border:"1px dashed #cacaca", borderRadius:"5px"}}>
                                    <div className='bg alert alert-warning' style={{padding:"15px", borderRadius:"5px", display:"flex", alignItems:"center"}}>
                                        <i style={{fontSize:"20px", color:"#000"}} className='fa fa-warning'/>
                                        <span style={{fontSize:"16px", color:"#000", marginLeft:"5px"}}>Nenhuma conversa foi iniciada!</span>
                                    </div>
                                </div>    
                            )}
                            {loadingInfinite && (
                                <div style={{display:"flex", gap:"5px", justifyContent:"center", padding:"15px 0px", alignItems:"center"}}>
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <i className='fa fa-spin fa-spinner'/>
                                    </div>
                                    <span>Carregando conversas</span>
                                </div>
                            )}
                        </div>
   
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}