import React, { useEffect, useState } from "react";

import { Button, Modal, ModalDialog } from "react-bootstrap";
import { ValidationForm } from "react-bootstrap4-form-validation";
import Draggable from "react-draggable";
import { useReactFlow } from "reactflow";
import api from '../../../services/api';
import {  getFields } from "./../../../components/Utils";
import ShowMessages from "../../../components/Exceptions";

class DraggableModalDialog extends React.Component {
    render() {
        return (
            <Draggable handle=".modal-header">
                <ModalDialog {...this.props} />
            </Draggable>
        );
    }
}

const actionOptions = [
    {
        label: "Se for o primeiro contato",
        value: 0,
    },
    {
        label: "Se existir os dados",
        value: 1,
    },
    {
        label: "Se o tipo da mensagem for",
        value: 2,
    },
    {
        label: "Se estiver na TAG",
        value: 3,
    },
    {
        label: "Se for palavras afirmativas",
        value: 4,
    },
    {
        label: "Se recebeu a última mensagem",
        value: 5,
    },
    {
        label: "Se for um Bot ou Mensagem Inicial Automatica",
        value: 6,
    }
];

export default function ModalConditionNode({ nodeId, showModal, handleCloseModal, nodeData }) {
    const [condition, setCondition]               = useState('');
    const [conditionId, setConditionId]           = useState(-1);
    const [conditionData, setCondtionData]        = useState([]);
    const [tag, setTag]                           = useState('')
    const [operator, setOperator]                 = useState('')
    const [type, setType]                         = useState('')
 
    const [fields, setFields]                     = useState([])
    const [dataTags,setDataTags]                  = useState([])
    const [options, setOptions]                   = useState([])
    const [testAffimartives, setTestAffimartives] = useState([])
    const [statusTest, setStatusTest]             = useState(99)
    const reactFlowInstance = useReactFlow();

    const handleCondition = (id,text) => {
        setConditionId(parseInt(id));
        setCondition(text);
    }

    const handleAffirmativeTest = async() => {
        await api.post('/affirmativetest', {body : testAffimartives})
                 .then(response => { 
                    console.log(response.data)
                    setStatusTest(response.data.score)
        })
        .catch( error => {      
            ShowMessages(error) 
        })
    }

    useEffect(() => {
        setConditionId(nodeData.conditionId);
        setCondition(nodeData.condition);
        setCondtionData(nodeData.conditionData);
        setType(nodeData.type)
        setOptions(nodeData.options)
        setFields(getFields().filter(_item => _item.inDatabase)) 
        getData()
    }, [nodeData]);


    const handleSubmit = (e) => {
        e.preventDefault();
        let options = []
        switch (conditionId) {
            case 0:
            case 1:
            case 3:
            case 4:
            case 5:
            case 6:
                options = [
                    {id : 4, value : "Sim", icon : 'fa fa-circle-check',targetId : -1},
                    {id : 5, value : "Não", icon : 'fa fa-circle-check',targetId : -1},
                ]  
                break;
            case 2:
                options = [
                    {id : 4, value : "Texto", icon : 'fa fa-keyboard',targetId : -1},
                    {id : 5, value : "Áudio", icon : 'fa fa-microphone',targetId : -1},
                    {id : 6, value : "Arquivo, Imagem ou Video", icon : 'fa fa-file',targetId : -1},
                    {id : 7, value : "Contato", icon : 'fa fa-id-card',targetId : -1},
                    {id : 8, value : "Lista", icon : 'fa fa-list',targetId : -1},
                    {id : 9, value : "Botão", icon : 'fa fa-mobile-screen',targetId : -1},
                    {id :10, value : "Localização", icon : 'fa fa-map-location',targetId : -1}
                ]  
                break;
            default:
                break;
        }

        reactFlowInstance.setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        nodeInfo: {
                            node       : parseInt(nodeData.node),
                            parentId   : parseInt(nodeData.parentId), 
                            targetId   : parseInt(nodeData.targetId),
                            objectType : 11,
                            condition,
                            conditionId,
                            conditionData,
                            type,
                            tag,
                            options
                        }
                    }
                }

                return node;
            })
        )

        handleCloseModal();
    };

    const addConditions = () => { 
        if (tag === '') {
            ShowMessages({status : 500, message : 'Informe o campo a ser analisado!'})
            return
        }

        if (operator === '') {
            ShowMessages({status : 500, message : 'Informe o operador a ser analisado!'})
            return
        }

        setCondtionData(condition => conditionData.concat({
            id: conditionData.length + 1,
            condition,
            conditionId,
            operator,
            tag
        }))

        setTag('')
        setOperator('')
    }

    const removeConditions = (id) => { 
        const _remove = conditionData.filter((t, i) => {
            if (t.id !== id)
                return true;
        
            return false;
        });
        setCondtionData(_remove)
    }

    const getData = async() => {
        try {
            await api.get('/getDataCampaing')
                    .then(response => { 
                        setDataTags(response.data.tags)
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            ShowMessages(response)
        }
    }

    return (
        <Modal size="md" onSubmit={handleSubmit} show={showModal} onHide={handleCloseModal} dialogClassName="width400" animation={true} backdrop="static" dialogAs={DraggableModalDialog}>
            <ValidationForm className="modal-content" setFocusOnError={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="fa fa-diagram-project"></i> Condição
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="box-shadow" style={{padding:"10px"}}>
                            <div className="mb-3">
                                <label>Escolha o tipo da condição</label>
                                <div className="input-group w-100">
                                    <select
                                        name="if"
                                        id="if"
                                        className="form-select w-100"
                                        placeholder="Selecione"
                                        onChange={(e) => handleCondition(e.target.value,e.target.options[e.target.selectedIndex].text)}
                                        value={conditionId}>
                                        <option value="">Escolha a condição</option>
                                            {actionOptions.map((data, id) => {
                                                return (<option key={id} value={data.value}>{data.label}</option>);
                                            })}
                                    </select>
                                </div>
                            </div>
                            {conditionId === 1 && (
                                <div className="mb-2">
                                    <div style={{borderTop:"1px dashed #cacaca", padding:"5px"}}></div>
                                    <div className="input-group">
                                        <div style={{display:"flex", gap:"5px"}}>
                                            <div style={{width:"100%"}}>
                                                <span>Se existir valor</span>
                                                <select className="form-select"  value={tag} onChange={e => setTag(e.target.value)}>
                                                    {
                                                        fields.map(_item => {
                                                            return (
                                                                <option value={_item.value}>{_item.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div style={{width:"100px", display:"flex", alignItems:"end"}}>
                                                <select className="form-select" value={operator} onChange={e => setOperator(e.target.value)}>
                                                    <option value=''>...</option>
                                                    <option value='E'>E</option>
                                                    <option value='OU'>OU</option>
                                                </select>
                                            </div>
                                            <div style={{width:"45px", display:"flex", alignItems:"end"}}>
                                                <button className="btn btn-success" type="button" onClick={e => addConditions()}>
                                                    <i className="fa fa-plus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div style={{marginTop:"5px"}}>
                                        <table className="table table-striped w-100">
                                            <thead className="thead-theme">
                                                <tr className='head-th'>
                                                    <td className='head-th'>Tag</td>
                                                    <td className='head-th'>Operador</td>
                                                    <td className='head-th' style={{textAlign:"center"}}>...</td>
                                                </tr>
                                            </thead>  
                                            <tbody>                        
                                                {conditionData.map((_actions,id) => {
                                                    return(<tr className="gradeX" key={id}>
                                                                <td style={{verticalAlign: "middle"}}>{_actions.tag}</td>
                                                                <td style={{verticalAlign: "middle"}}>{_actions.operator}</td>
                                                                <td style={{textAlign:"center",verticalAlign: "middle"}}>
                                                                    <button type='button' className="btn btn-danger btn-sm" onClick={e => removeConditions(_actions.id)}><i className="fa fa-trash"/></button>
                                                                </td>
                                                            </tr>)
                                                            }) }
                                            </tbody> 
                                        </table>
                                    </div>
                                </div>
                            )}

                            {conditionId === 3 && (
                                <div className="mb-2">
                                    <div style={{borderTop:"1px dashed #cacaca", padding:"5px"}}></div>
                                    <div className="input-group">
                                        <div style={{display:"flex", gap:"5px"}}>
                                            <div style={{width:"100%"}}>
                                                <span>TAG</span>
                                                <select className="form-select"  value={tag} onChange={e => setTag(e.target.value)}>
                                                    {
                                                        dataTags.map(_item => {
                                                            console.log(_item)
                                                            return (
                                                                <option value={_item}>{_item}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {conditionId === 4 && (
                                <div className="mb-2">
                                    <div style={{display:"flex", gap:"5px", flexDirection:"column"}}>
                                        <div>
                                            <span>Testar frases ou palavras</span>
                                            <textarea className="form-control" value={testAffimartives} onChange={e => setTestAffimartives(e.target.value)}></textarea>
                                        </div>
                                        <div style={{display:"flex", gap:"5px"}}>
                                            <div>
                                                <button type="button" className="btn btn-success" onClick={e => handleAffirmativeTest()}><i className="fa fa-robot"/>Testar Afirmação</button>
                                            </div>
                                            {statusTest < 0 && (
                                                <div className="alert alert-danger" style={{width:"200px", textAlign:"center", padding:"7px"}}>
                                                    <span>Negativa</span>
                                                </div>
                                            )}

                                            {statusTest === 0 && (
                                                <div className="alert alert-primary" style={{width:"200px", textAlign:"center", padding:"7px"}}>
                                                    <span>Neutro | Positivo</span>
                                                </div>
                                            )}

                                            {statusTest > 0 && statusTest < 98 && (
                                                <div className="alert alert-success" style={{width:"200px", textAlign:"center", padding:"7px"}}>
                                                    <span>Positivo</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" id="submit-action-info">
                        <em className="fa fa-save"></em> Aplicar
                    </Button>
                    <Button type="button" className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm>
        </Modal>
  );
}
