import React, {useEffect} from 'react'
import setStatistic from '../../services/apiStatistic'
import api from '../../services/api';
import { useParams } from 'react-router-dom'

export default function Clicks() {

    const { id } = useParams();

    useEffect(() => {
        const setData = async() => {
            await api.post('/click',{code : id})
                     .then((response) => {
                       
                     
                     }).catch(error => {
                        
                     }) 
        }
        if (id && id !== "") {
            setData()
            window.location.href = 'https://55zap.com.br'
        }
        setStatistic('external\\clicks')
    } ,[id])

    return (
        <div style={{display:"flex", justifyContent:"center",alignItems:"center", height:"100vh"}}>
            Redirecionando...
        </div>
    )
}