import React, { useEffect, useState } from 'react';

import { Modal, ModalDialog } from 'react-bootstrap';
import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation';
import Draggable from 'react-draggable';
import MaskedInput from 'react-text-mask';
import { Button, Nav, NavLink, NavItem, TabPane, TabContent  } from 'reactstrap';
import ShowMessages from '../../components/Exceptions';
import CEP from 'cep-promise'

import Loading from '../../components/Loading';
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic';
import loadingButton from '../layouts/Buttons';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { TagsInput } from "react-tag-input-component";

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalLead({showModal, handleCloseModal,editState, data, handleReload, copy}) {
    const [tabActive, setTabActive]                 = useState(0)
    const [loading, setLoading]                     = useState(true)
    const [name, setName]                           = useState('');
    const [phone, setPhone]                         = useState('');
    const [cep, setCEP]                             = useState('');
    const [address, setAddress]                     = useState('');
    const [complement, setComplement]               = useState('');
    const [number, setNumber]                       = useState('');
    const [city, setCity]                           = useState('');
    const [uf, setUF]                               = useState('');
    const [country, setCountry]                     = useState('');
    const [neighborhood, setNeighborhood]           = useState('');
    const [facebook, setFacebook]                   = useState('');
    const [instagram, setInstagram]                 = useState('');
    const [linkedin, setLinkedin]                   = useState('');
    const [telegram, setTelegram]                   = useState('');
    const [email, setEmail]                         = useState('');
    const [site, setSite]                           = useState('');
    const [tags, setTags]                           = useState([]);
    const [annotations, setAnnotations]             = useState('');

    const [dataTags, setDataTags]                   = useState([])
    const [tag, setTag]                             = useState('')


    const getAddress = (value) => {
        let _cep = value.replace(/\D/g,'')
        if (_cep.length === 8) {
            CEP(_cep)
            .then((data) => {
                setAddress(data.street)
                setCEP(value)
                setNeighborhood(data.neighborhood)
                setUF(data.state)
                setCity(data.city)
                setCountry('Brasil')
            })
            .catch()
        } else {
            setAddress('')
            setNeighborhood('')
            setUF('')
            setCity('')
            setCountry('')
        }    
    }

    const openURL = (url) => {
        window.open(url,'_blank');
        window.open(url);
    }

    const addValidationTag = (_tag) => {
        if (_tag.trim() === '')
            return false
        _tag = _tag.toString().toUpperCase()
        return !tags.includes(_tag)
    }

    const setData = (obj,clear) => {
        let _number = (obj?.phones || '').replace(/[\W_,[a-z]+/g,"")
        setTabActive(0)
        setTag('')
        getAddress('')
        setName(clear ? ''  : obj.name);
        setPhone(clear ? '' : (_number.length <= 11 ? '+55' : '+') + _number);
        setCEP(clear ? '' : obj.addresses?.cep || '');
        setAddress(clear ? '' : obj.addresses?.street || '');
        setComplement(clear ? '' : obj.addresses?.complement || '');
        setNumber(clear ? '' : obj.addresses?.number || '');
        setCity(clear ? '' : obj.addresses?.city || '');
        setUF(clear ? '' : obj.addresses?.uf || '');
        setCountry(clear ? '' : obj.addresses?.country || '');
        setNeighborhood(clear ? '' : obj.addresses?.neighborhood || '');
        setFacebook(clear ? ''  : obj.contacts?.filter((item) => {return item.idtype === 2})[0]?.value  || '');
        setInstagram(clear ? '' : obj.contacts?.filter((item) => {return item.idtype === 1})[0]?.value  || '');
        setLinkedin(clear ? ''  : obj.contacts?.filter((item) => {return item.idtype === 4})[0]?.value  || '');
        setTelegram(clear ? ''  : obj.contacts?.filter((item) => {return item.idtype === 5})[0]?.value  || '');
        setEmail(clear ? ''     : obj.contacts?.filter((item) => {return item.idtype === 0})[0]?.value  || '');
        setSite(clear ? ''      : obj.contacts?.filter((item) => {return item.idtype === 6})[0]?.value  || '');
        setTags(clear ? [] : (Object.keys(obj?.tags || {}).length === 0) ? [] : Array.isArray(obj.tags[0]) ? obj.tags[0] : obj.tags || []);
        setAnnotations(clear ? '' : obj.annotations || '')
        setLoading(false)
    }

    const handleTagInput = (value) => { 
        value = (value || []).map(function(x){ return x.toString().toUpperCase().replace(/[^a-zA-Z0-9_]/g,''); })     
        if (value === '')
            return false

        if (tags?.length !== value.length)
            setTags(value)
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        if (phone === '') {
            ShowMessages({status : 500, message : "Telefone não foi informada!"})
            return
        }

        if (facebook.indexOf('facebook.com') >= 0) {
            ShowMessages({status : 500, message : "Informe apenas o usuário do facebook"})
            return
        }

        if (instagram.indexOf('instagram.com') >= 0) {
            ShowMessages({status : 500, message : "Informe apenas o usuário do instagram"})
            return
        }

        if (linkedin.indexOf('linkedin.com') >= 0) {
            ShowMessages({status : 500, message : "Informe apenas o usuário do linkedin"})
            return
        }

        if (tags.length <= 0) {
            ShowMessages({status : 500, message : "Nenhuma tag foi informada!"})
            return
        }

        loadingButton(true,document.querySelector('#submit-lead'),document.querySelector('#submit-lead').children[0].classList,document.querySelector('#submit-lead').lastChild.nodeValue)
        try {
            let _dataToSend = {
                name,
                phone,
                cep,
                address,
                complement,
                number,
                city,
                uf,
                country,
                neighborhood,
                facebook,
                instagram,
                linkedin,
                telegram,
                email,
                site,
                tags,
                annotations
            }

            if ((editState) && (!copy))
                await api.put('/leads/' + data.idpeople, _dataToSend)
                      .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                                 setTags([]);
                             } else
                                 loadingButton(false,document.querySelector('#submit-lead'))
                         } else
                             loadingButton(false,document.querySelector('#submit-lead'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-lead'))
                         ShowMessages(error) 
                     })
            else
                await api.post('/leads', _dataToSend)
                      .then(response => {
                        ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)  
                                 setTags([]);  
                             } else 
                                 loadingButton(false,document.querySelector('#submit-lead'))        
                          } else
                             loadingButton(false,document.querySelector('#submit-lead'))
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-lead'))
                         ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-lead'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        if (editState) 
            setData(data || {},false)
        else 
            setData({},true)
    },[data,editState, showModal])


    useEffect(() => {
        getData()
        setStatistic('modals\\modal-lead')
    } ,[])

    const getData = async() => {
        try {
            await api.get('/getDataCampaing')
                    .then(response => { 
                        setDataTags(response.data.tags)
                    })
                    .catch( error => {      
                        ShowMessages(error) 
                    })
        } catch (response) { 
            ShowMessages(response)
        }
    }

    return (
        <Modal size="lg" show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName="width550" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-user"></i> Propriedades do Lead
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className='box-shadow'>
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={tabActive === 0 ? "active" : ""} onClick={() => { setTabActive(0)}}>
                                    <i className='fa fa-person'/> Informações
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 1 ? "active" : ""} onClick={() => { setTabActive(1)}}>
                                    <i className='fa fa-note-sticky'/> Anotações
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 2 ? "active" : ""} onClick={() => { setTabActive(2)}}>
                                    <i className='fa fa-chart-bar'/> Ciclo de Vida
                                </NavLink>
                            </NavItem>
                        </Nav>
                    
                        <TabContent activeTab={tabActive} style={{border:"0px", padding:"10px 0px", height:"570px"}}>
                            <TabPane tabId={0}>                            
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"80%"}}>               
                                        <label>Nome Completo</label>
                                        <div className="input-group" style={{marginBottom: '10px'}}>
                                            <TextInput  
                                                name="name"
                                                id="name"
                                                type="text"
                                                autoFocus={true}
                                                maxLength={100}
                                                value={name}
                                                className="form-control "
                                                onChange={e => setName(e.target.value)}
                                                autoComplete="off"/>
                                        </div>  
                                    </div>
                                    <div>
                                        <label>Telefone</label>
                                        <div className="input-group">
                                        <PhoneInput
                                            placeholder=""
                                            initialValueFormat="national"
                                            style={{display:'flex', padding:"0.295rem 0.75rem"}}
                                            value={phone}
                                            country="BR"
                                            defaultCountry="BR"
                                            className='form-control'
                                            onChange={setPhone}/>
                                        </div>
                                    </div>  
                                </div>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"250px"}}>               
                                        <label>CEP</label>
                                        <button type="button" className="btn btn-warning button-correios" onClick={e => openURL('https://buscacepinter.correios.com.br/app/endereco/index.php')}><i className='fa fa-sm fa-truck-fast'></i> Correios</button>
                                        <div className="input-group" style={{marginBottom: '10px'}}>
                                            <MaskedInput  
                                                name="cep"
                                                id="cep"
                                                type="text"
                                                value={cep}
                                                className="form-control"
                                                onChange={e => getAddress(e.target.value)}
                                                mask={ [/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/] }
                                                autoComplete="off"/>  
                                        </div>  
                                    </div>
                                    <div style={{width:"100%"}}>               
                                        <label>Endereço</label>
                                        <div className="input-group" style={{marginBottom: '10px'}}>
                                            <TextInput  
                                                name="address"
                                                id="address"
                                                type="text"
                                                maxLength={100}
                                                value={address}
                                                className="form-control "
                                                onChange={e => setAddress(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div style={{width:"25%"}}>               
                                        <label>Número</label>
                                        <div className="input-group" style={{marginBottom: '10px'}}>
                                            <TextInput  
                                                name="number"
                                                id="number"
                                                type="text"
                                                maxLength={25}
                                                value={number}
                                                className="form-control "
                                                onChange={e => setNumber(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"50%"}}>               
                                        <label>Complemento</label>
                                        <div className="input-group" style={{marginBottom: '10px'}}>
                                            <TextInput  
                                                name="complement"
                                                id="complement"
                                                type="text"
                                                maxLength={100}
                                                value={complement}
                                                className="form-control "
                                                onChange={e => setComplement(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div style={{width:"50%"}}>               
                                        <label>Bairro</label>
                                        <div className="input-group" style={{marginBottom: '10px'}}>
                                            <TextInput  
                                                name="neighborhood"
                                                id="neighborhood"
                                                type="text"
                                                maxLength={100}
                                                value={neighborhood}
                                                className="form-control "
                                                onChange={e => setNeighborhood(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"50%"}}>               
                                        <label>Cidade</label>
                                        <div className="input-group" style={{marginBottom: '10px'}}>
                                            <TextInput  
                                                name="city"
                                                id="city"
                                                type="text"
                                                maxLength={150}
                                                value={city}
                                                className="form-control "
                                                onChange={e => setCity(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div style={{ width:"70px"}}>               
                                        <label>UF</label>
                                        <div className="input-group" style={{marginBottom: '10px'}}>
                                            <TextInput  
                                                name="uf"
                                                id="uf"
                                                type="text"
                                                maxLength={2}
                                                value={uf}
                                                className="form-control "
                                                onChange={e => setUF(e.target.value.toUpperCase())}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div style={{width:"50%"}}>               
                                        <label>País</label>
                                        <div className="input-group" style={{marginBottom: '10px'}}>
                                            <TextInput  
                                                name="country"
                                                id="country"
                                                type="text"
                                                maxLength={100}
                                                value={country}
                                                className="form-control "
                                                onChange={e => setCountry(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"40%"}}>               
                                        <label>Site</label>
                                        <div style={{display:"flex", gap:"1px"}}>
                                            <div className="input-group" style={{marginBottom: '10px'}}>
                                                <TextInput  
                                                    name="site"
                                                    id="site"
                                                    type="text"
                                                    maxLength={500}
                                                    value={site}
                                                    className="form-control "
                                                    onChange={e => setSite(e.target.value.toString().toLowerCase())}
                                                    autoComplete="off"/>
                                            </div>
                                            <div >
                                                <button type="button" className="btn btn-primary" title={`Ir para o site ${site}`} onClick={e => openURL(site.indexOf('http') >= 0 ? site : 'https://' + site)}><i className="fab fa-chrome"/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{width:"25%"}}>               
                                        <label>Facebook</label>
                                        <div style={{display:"flex", gap:"1px"}}>
                                            <div className="input-group" style={{marginBottom: '10px'}}>
                                                <TextInput  
                                                    name="facebook"
                                                    id="facebook"
                                                    type="text"
                                                    maxLength={100}
                                                    value={facebook}
                                                    className="form-control "
                                                    onChange={e => setFacebook(e.target.value.toString().toLowerCase())}
                                                    autoComplete="off"/>
                                                <small className="text-danger">Ex: usuario.123</small>
                                            </div>
                                            <div>
                                                <button type="button" className="btn btn-primary" title={`Ir para o facebook ${facebook}`} onClick={e => openURL('https://facebook.com/' + facebook)}><i className="fab fa-facebook"/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div  style={{width:"35%"}}>              
                                        <label>Email</label>
                                        <div className="input-group" style={{marginBottom: '10px'}}>
                                            <TextInput  
                                                name="email"
                                                id="email"
                                                type="text"
                                                maxLength={100}
                                                value={email}
                                                className="form-control "
                                                onChange={e => setEmail(e.target.value.toString().toLowerCase())}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:"flex", gap:"5px"}}>
                                    <div style={{width:"50%", gap:"1px"}}>               
                                        <label>Linkedin</label>
                                        <div style={{display:"flex", gap:"1px"}}>
                                            <div className="input-group" style={{marginBottom: '10px'}}>
                                                <TextInput  
                                                    name="linkedin"
                                                    id="linkedin"
                                                    type="text"
                                                    maxLength={100}
                                                    value={linkedin}
                                                    className="form-control "
                                                    onChange={e => setLinkedin(e.target.value.toString().toLowerCase())}
                                                    autoComplete="off"/>
                                                <small className="text-danger">Ex: usuario.123</small>
                                            </div>
                                            <div >
                                                <button type="button" className="btn btn-primary" title={`Ir para o linkedin ${linkedin}`} onClick={e => openURL('https://www.linkedin.com/in/' + linkedin)}><i className="fab fa-linkedin"/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div  style={{width:"50%"}}>               
                                        <label>Instagram</label>
                                        <div style={{display:"flex", gap:"1px"}}>
                                            <div className="input-group" style={{marginBottom: '10px'}}>
                                                <TextInput  
                                                    name="instagram"
                                                    id="instagram"
                                                    type="text"
                                                    maxLength={100}
                                                    value={instagram}
                                                    className="form-control "
                                                    onChange={e => setInstagram(e.target.value.toString().toLowerCase())}
                                                    autoComplete="off"/>
                                                <small className="text-danger">Ex: usuario.123</small>
                                            </div>
                                            <div>
                                                <button type="button" className="btn btn-primary" title={`Ir para o instagram ${instagram}`} onClick={e => openURL('https://www.instagram.com/' + instagram)}><i className="fab fa-instagram"/></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{width:"50%"}}>             
                                        <label>Telegram</label>
                                        <div className="input-group" style={{marginBottom: '10px'}}>
                                            <TextInput  
                                                name="telegram"
                                                id="telegram"
                                                type="text"
                                                maxLength={100}
                                            value={telegram}
                                            className="form-control "
                                            onChange={e => setTelegram(e.target.value)}
                                            autoComplete="off"/>
                                        </div>
                                    </div>  
                                </div>
                                <div style={{display:"flex", gap:"15px"}}>  
                                    <div style={{width:"400px"}}>
                                        <label>Tags</label>
                                        <div className="input-group">
                                            <TagsInput value={tags} onChange={e => handleTagInput(e)} beforeAddValidate={e => addValidationTag(e)}/>
                                        </div> 
                                        <small className="text-danger">Após digitar a <span style={{fontWeight:"bold"}}>Tag</span>, aperte <span style={{fontWeight:"bold"}}>Enter</span></small>
                                    </div>     
                                    <div>
                                        <button title="Click aqui para incluir uma tag já existente" type="button" className="btn btn-success" style={{marginTop:"15px"}} onClick={e => handleTagInput(addValidationTag(tag) ? [...tags,tag] : tags)}><i className="fa fa-arrow-left"></i></button>
                                    </div>       
                                    <div style={{width:"300px"}}>
                                        <div>
                                            <span>Tags já existentes</span>
                                            <select className="form-select" value={tag} onChange={e => setTag(e.target.value)} style={{minHeight:"37px"}}>
                                                <option value="">Selecione</option>
                                                {(dataTags || []).map((item) => {
                                                    return(<option value={item}>{item}</option>)
                                                })} 
                                            </select>
                                        </div>
                                        <small className="text-danger">Selecione uma <span style={{fontWeight:"bold"}}>Tag</span> e click no botão ao lado para inserir</small>
                                    </div>
                                </div>          
                            </TabPane>
                            <TabPane tabId={1}>
                                <div>
                                    <label>Anotações</label>
                                    <textarea className='form-control' rows={27} value={annotations} onChange={e => setAnnotations(e.target.value)}/>
                                </div>
                            </TabPane>
                            <TabPane tabId={2}>

                            </TabPane>
                        </TabContent>
                    
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-lead" className={`btn-theme`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}