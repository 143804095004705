import React, {useState, useEffect} from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import {phone_format} from './../../components/Utils'
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalChatbot({showModal, handleCloseModal, to, handleReload}) {
    const [loading, setLoading]      = useState(false)

    const [idAutomationChat,setIDAutomationChat] = useState(0)
    const [chatdata, setChatData]    = useState([])

    const getData = async() => {
        const getData = async() => {
            await api.get('/getChatBotGroup')
                     .then(async response => {
                        setChatData(response.data)
                     })
                     .catch( error => {
                        ShowMessages(error)
                        setLoading(false) 
                     })
        }

        getData()
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-chatbot'),document.querySelector('#submit-chatbot').children[0].classList,document.querySelector('#submit-chatbot').lastChild.nodeValue)
        try {

            await api.post('/startchatbot', {idautomationchat : idAutomationChat,to})
                     .then(response => {
                         ShowMessages(response)
                         if (response.ok) {
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else
                                 loadingButton(false,document.querySelector('#submit-chatbot'))
                         } else
                             loadingButton(false,document.querySelector('#submit-chatbot'))
                     })
                     .catch( error => {
                         loadingButton(false,document.querySelector('#submit-chatbot'))
                         ShowMessages(error) 
                     })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-chatbot'))
            ShowMessages(response)
        }
    }
       
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-chatbot')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-robot"></i> Iniciar Chatbot
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading}/>
                    )}
                    <div className={` box-shadow ${loading ? "hidden" : ""}`}>               
                        <div style={{display:"flex"}}>
                            <div>
                                <div style={{display:"flex"}}>
                                    <div style={{width:"350px"}}>
                                        <span>Automação</span>
                                        <select className="form-select" value={idAutomationChat} onChange={e => setIDAutomationChat(e.target.value)}>
                                            <option value="">Selecione</option>
                                            {chatdata.map((item) => {
                                                return(<option value={item.idautomationchat}>{`${phone_format(item.number)} | ${item.title}`}</option>)
                                            })}
                                        </select>
                                    </div>
                                                
                                </div>
                            </div>
                        </div>                              
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-chatbot" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-play"></em> Iniciar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}