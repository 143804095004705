import { memo, useCallback, useEffect, useState } from "react";
import { Handle,  Position, useReactFlow } from "reactflow";
import ModalMessageNode from "./../../../pages/modals/workflow/modal-message-node";
import { messageCustom } from "./../../Utils"

const MessageNode = (props) => {
    const reactFlowInstance               = useReactFlow();
    const [showModal, setShowModal]       = useState(false);
    const [message, setMessage]           = useState("");
    const [id, setID]                     = useState(-1)
    const [statistic, setStatistic]       = useState(0)
    const [timeToAction, setTimeToAction] = useState(0)

    useEffect(() => {
        const handleNodeProps = () => {
            const nodeInfo = props.data.nodeInfo;
            setMessage(nodeInfo.message);
            setID(parseInt(nodeInfo.node))
            setStatistic(nodeInfo.statistic)
            setTimeToAction(nodeInfo.timeToAction || 0)
        };
        handleNodeProps();
    }, [props.data]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const copy = (e) => {
        e.stopPropagation()
        let _id  = window.getId()
        let _get = reactFlowInstance.getNodes()
        _get = _get.filter(_item => {
            return parseInt(_item.id) === parseInt(props.id)
        })[0]

        reactFlowInstance.setNodes((nds) => nds.concat( { 
            ...props,
            id       :_id,
            position : { x: _get.position.x, y: _get.position.y  + _get.height + 20},
            height   : _get.height,
            width    : _get.width,
            data     : {
                nodeInfo: {
                    node         : _id,
                    parentId     : -1, 
                    targetId     : -1,
                    timeToAction : props.data.nodeInfo.timeToAction,
                    message      : props.data.nodeInfo.message,
                    objectType   : 1
                                        
                }
            }
        }))
    }

    const deleteNode = useCallback(() => {
        reactFlowInstance.deleteElements({ nodes: [props] });
    }, [reactFlowInstance, props]);

    return (
        <>
            <ModalMessageNode
                nodeId={props.id}
                nodeData={props.data.nodeInfo}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
            ></ModalMessageNode>

            <div className="rfnode rfnode-message d-flex flex-column justify-content-center align-items-center h-100 bg-light box-shadow"  onClick={(e) => setShowModal(true)}>
                <div className="rfnode-title">#{id} Mensagem de Texto</div>

                <div style={{background: "rgba(111, 66, 193, 0.1098039216)", margin:"5px 10px", padding:"5px", borderRadius:"5px", color:"#6f42c1", width:"85%", textAlign:"center"}}>
                    {message.length === 0 ? (
                        <div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <i className="fa fa-keyboard"/>
                            </div>
                            <span style={{fontSize:"6px"}}>Aqui você adiciona uma mensagem de texto a ser enviada!</span>
                        </div>
                    ) : (
                        <pre style={{fontSize:"6px",textAlign:"left", textWrap:"wrap", overflow:"hidden"}} dangerouslySetInnerHTML={{__html: messageCustom(message,'')}}></pre>
                    )}
                </div>
                {timeToAction > 0 && (
                    <div style={{display:"flex",gap:"2px", alignItems:"center", color:'#6f42c1', marginBottom:"5px"}}>
                        <i className="fa fa-keyboard" style={{fontSize:"7px"}}/> 
                        <span style={{fontSize:"7px"}}>Digitando por {timeToAction} segundos</span>
                    </div>
                )}
                <div style={{display:"flex", fontSize:"8px", padding:"3px 5px", justifyContent:"space-between", width:"100%", borderTop:"1px dashed #6f42c1"}}>
                    <div style={{display:"flex", gap:"5px"}}>
                        <div onClick={e => copy(e)} title="Copiar Ação" className="workflow-copy" >
                            <i className="fa fa-copy text-primary"></i>
                        </div>
                        <div onClick={() => deleteNode()} title="Excluir Mensagem" className="workflow-delete" >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
                    </div>
                    <div title="Número de vezes que o lead passou pela ação">
                        <span style={{fontSize:"10px", color:"#6f42c1"}}>{statistic || ''}</span>
                    </div>
                </div>
            </div>

            <Handle type="source" position={Position.Bottom} id='node-message1'  />
            <Handle type="target" position={Position.Top} id='node-message2' />
            <Handle type="target" position={Position.Left} id='node-message3' />
            <Handle type="source" position={Position.Right} id='node-message4'  />
        </>
  );
};

export default memo(MessageNode);
