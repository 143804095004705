import React, { useState, useEffect } from 'react'
import ContentWrapper from '../../pages/layouts/contentWrapper'
import api from './../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,  PieChart, Pie,ResponsiveContainer } from 'recharts';
import { format,differenceInDays }   from 'date-fns'
import Loading from '../../components/Loading';

export default function DashboardGeneral() {
    const [loading, setLoading]                     = useState(false)
    const [leads, setLeads]                         = useState(0)
    const [tags, setTags]                           = useState(0)
    const [automations, setAutomations]             = useState(0)
    const [activeAutomations, setActiveAutomations] = useState(0)
    const [activeFlux, setActiveFlux]               = useState(0)
    const [flowCompleted, setflowCompleted]         = useState(0)
    const [messageSend, setMessageSend]             = useState(0)
    const [ackDevice, setACKDevice]                 = useState(0)
    const [ackRead, setACKRead]                     = useState(0)
    const [passiveFlux, setPassiveFlux]             = useState(0)
    const [flowCompletedP, setflowCompletedP]       = useState(0)

    const [idautomationchat, setIDAutomationChat]   = useState(0)

    const [automationChat, setAutomationChat]               = useState([])
    const [schedullingAutomation, setSchedullingAutomation] = useState([])


    const getData = async() => {
        setLoading(true)
        await api.get('/dashboard-general')
                 .then(response => {
                    let data = response.data
                    setLeads(data.leads)
                    setTags(data.tags)
                    setActiveAutomations(data.activeautomations)
                    setAutomations(data.automations)
                    setActiveFlux(data.activeFlux)
                    setflowCompleted(data.flowCompleted)
                    setMessageSend(data.messageSend)
                    setACKDevice(data.ACK_DEVICE)
                    setACKRead(data.ACK_READ)
                    setPassiveFlux(data.passiveFlux)
                    setflowCompletedP(data.flowCompletedP)
                    setAutomationChat(data.automationchat)
                    setSchedullingAutomation(data.schedullingAutomation)
                    setIDAutomationChat(0)
                    setLoading(false)
                 })
                 .catch((error) => {
                    ShowMessages(error)
                 })
    }

    useEffect(() => {
        getData()
        setStatistic('maintenance\\admin\\dashboard-general')
    },[])

    
    return (    
        <ContentWrapper>
            {loading && (
                <Loading loading={loading} />
            )}
            <div className={`box-shadow content-box-cart ${(loading ? "hidden" : "")}`} style={{padding:"10px 10px 10px 20px", display:"flex", flexDirection:"column", overflowY:"auto", height:"85vh", overflowX:"hidden"}}>
                <div style={{display:"flex", gap:"14px", flexWrap:"wrap"}}>
                    <div style={{width:"24%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"0px"}}>
                            <div className="col-4 d-flex align-items-center bg bg-primary-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-people-line fa-2x"></em>
                            </div>
                            <div className="col-8 alert alert-primary rounded-right" style={{padding:"1px", borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h3 mt-0" style={{textAlign:"right", paddingRight:"25px"}}>{leads}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Total de Leads</div>
                            </div>
                        </div>
                    </div> 
                    <div style={{width:"24%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"0px"}}>
                            <div className="col-4 d-flex align-items-center bg bg-primary-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-tag fa-2x"></em>
                            </div>
                            <div className="col-8 alert alert-primary rounded-right" style={{padding:"1px", borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h3 mt-0" style={{textAlign:"right", paddingRight:"25px"}}>{tags}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Total de Tags</div>
                            </div>
                        </div>
                    </div> 
                    <div style={{width:"24%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"0px"}}>
                            <div className="col-4 d-flex align-items-center bg bg-warning-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-robot fa-2x"></em>
                            </div>
                            <div className="col-8 alert alert-danger rounded-right" style={{padding:"1px", borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h3 mt-0" style={{textAlign:"right", paddingRight:"25px"}}>{automations}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Total Automações</div>
                            </div>
                        </div>
                    </div> 
                    <div style={{width:"24%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"0px"}}>
                            <div className="col-4 d-flex align-items-center bg bg-warning-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-play fa-2x"></em>
                            </div>
                            <div className="col-8 alert alert-danger rounded-right" style={{padding:"1px", borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h3 mt-0" style={{textAlign:"right", paddingRight:"25px"}}>{activeAutomations}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Automações Ativas</div>
                            </div>
                        </div>
                    </div> 
                    <div style={{width:"19%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"0px"}}>
                            <div className="col-4 d-flex align-items-center bg bg-success-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-repeat fa-2x"></em>
                            </div>
                            <div className="col-8 alert alert-success rounded-right" style={{padding:"1px", borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h3 mt-0" style={{textAlign:"right", paddingRight:"25px"}}>{activeFlux}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>INTERAÇÕES INICIADAS   </div>
                            </div>
                        </div>
                    </div>  
                    <div style={{width:"19.5%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"0px"}}>
                            <div className="col-4 d-flex align-items-center bg bg-success-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-flag-checkered fa-2x"></em>
                            </div>
                            <div className="col-8 alert alert-success rounded-right" style={{padding:"1px", borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div>
                                    <div className="h3 mt-0" style={{textAlign:"right", paddingRight:"25px"}}>{flowCompleted}</div>
                                    <div className="h2" style={{textAlign:"right", paddingRight:"10px", fontSize:"12px", position:'absolute', width:"100%",marginTop: "-13px"}}>
                                        <span>{(flowCompleted / (activeFlux === 0 ? 1 : activeFlux) * 100).toFixed(2)}%</span></div>
                                    </div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Interações Concluídas</div>
                            </div>
                        </div>
                    </div> 
                    <div style={{width:"19%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"0px"}}>
                            <div className="col-4 d-flex align-items-center bg bg-success-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-comment fa-2x"></em>
                            </div>
                            <div className="col-8 alert alert-success rounded-right" style={{padding:"1px", borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h3 mt-0" style={{textAlign:"right", paddingRight:"25px"}}>{messageSend}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Mensagens Enviadas</div>
                            </div>
                        </div>
                    </div>  
                    <div style={{width:"19%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"0px"}}>
                            <div className="col-4 d-flex align-items-center bg bg-success-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-paper-plane fa-2x"></em>
                            </div>
                            <div className="col-8 alert alert-success rounded-right" style={{padding:"1px", borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h3 mt-0" style={{textAlign:"right", paddingRight:"25px"}}>{ackDevice}</div>
                                <div className="h2" style={{textAlign:"right", paddingRight:"10px", fontSize:"12px", position:'absolute', width:"100%",marginTop: "-13px"}}>
                                    <span>{(ackDevice / (messageSend === 0 ? 1 : messageSend) * 100).toFixed(2)}%</span>
                                </div>
                                
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Mensagens Entregues</div>
                            </div>
                        </div>
                    </div>  
                    <div style={{width:"19%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"0px"}}>
                            <div className="col-4 d-flex align-items-center bg bg-success-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-envelope-open fa-2x"></em>
                            </div>
                            <div className="col-8 alert alert-success rounded-right" style={{padding:"1px", borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h3 mt-0" style={{textAlign:"right", paddingRight:"25px"}}>{ackRead}</div>
                                <div className="h2" style={{textAlign:"right", paddingRight:"10px", fontSize:"12px", position:'absolute', width:"100%",marginTop: "-13px"}}>
                                    <span>{(ackRead / (ackDevice === 0 ? 1 : ackDevice) * 100).toFixed(2)}%</span>
                                </div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Mensagens Abertas</div>
                            </div>
                        </div>
                    </div>  
                </div>
                <div style={{display:"flex", gap:"15px"}}>
                    <div style={{ display: "flex", marginTop: "15px", width: "100%" }}>
                        <div style={{ height: "450px", padding: "15px 5px", width: "100%" }} className="box-shadow">
                            <div style={{ display: "flex" }}>
                                <div style={{ padding: "5px 10px", width: "350px" }}>
                                    <label>Automação</label>
                                    <select className='form-select' value={idautomationchat} onChange={e => setIDAutomationChat(parseInt(e.target.value))}>
                                        <option value={0}>Selecione</option>
                                        {automationChat.map(_item => {
                                            return (
                                                <option value={_item.idautomationchat}>{_item.title}</option> 
                                            )
                                        })}
                                    </select>
                                </div>
                                <div style={{ padding: "5px", width: "350px" }}>
                                    <label>Interação</label>
                                    <select className='form-select'>
                                        <option>Selecione</option>
                                        {schedullingAutomation.filter(_item => {return parseInt(_item.idautomationchat) === parseInt(idautomationchat)}).map(_item => {
                                            return (
                                                <option value={_item.idautomationscheduling}>{format(new Date(_item.create_at),'dd/MM/yyyy HH:mm')}</option> 
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div style={{ padding: "5px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                <span style={{ fontSize: "16px", fontWeight: "bold" }}><i className="fa fa-chart-line"></i> Aproveitamento do Fluxo Ativo</span>
                            </div>
                            <ResponsiveContainer width="100%" height="80%">
                                <BarChart
                                    width={"100%"}
                                    height={300}
                                    data={[]}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <YAxis dataKey="total" domain={[0, 20]} />
                                    <XAxis dataKey="dia" />
                                    <Legend />
                                    <Tooltip />
                                    <Bar dataKey="total" fill="#8884d8" stroke="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                                    <div>
                    <div style={{width:"24%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"0px"}}>
                            <div className="col-4 d-flex align-items-center bg bg-info-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-comment-dots fa-2x"></em>
                            </div>
                            <div className="col-8 alert alert-info rounded-right" style={{padding:"1px", borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h3 mt-0" style={{textAlign:"right", paddingRight:"25px"}}>{passiveFlux}</div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Interações Passivas</div>
                            </div>
                        </div>
                    </div>  
                    <div style={{width:"24%"}}>
                        <div className="card flex-row align-items-center align-items-stretch border-0" style={{marginBottom:"0px"}}>
                            <div className="col-4 d-flex align-items-center bg bg-info-dark justify-content-center rounded-left" style={{borderTopLeftRadius:"7px", borderBottomLeftRadius:"7px"}}>
                                <em className="fa fa-flag-checkered fa-2x"></em>
                            </div>
                            <div className="col-8 alert alert-info rounded-right" style={{padding:"1px", borderTopRightRadius:"7px", borderBottomRightRadius:"7px",borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", marginBottom:"0px"}}>
                                <div className="h3 mt-0" style={{textAlign:"right", paddingRight:"25px"}}>{flowCompletedP}</div>
                                <div className="h2" style={{textAlign:"right", paddingRight:"10px", fontSize:"12px", position:'absolute', width:"100%",marginTop: "-13px"}}>
                                    <span>{(flowCompletedP / (passiveFlux === 0 ? 1 : passiveFlux) * 100).toFixed(2)}%</span>
                                </div>
                                <div className="text-uppercase" style={{textAlign:"right", paddingRight:"10px"}}>Interações Concluídas</div>
                            </div>
                        </div>
                    </div>  
                </div>

                <div style={{display:"flex", gap:"15px"}}>
                    <div style={{ display: "flex", marginTop: "15px", width: "49%" }}>
                        <div style={{ height: "450px", padding: "15px 5px", width: "100%" }} className="box-shadow">
                            <div style={{ display: "flex" }}>
                                <div style={{ padding: "5px 10px", width: "350px" }}>
                                    <label>Automação</label>
                                    <select className='form-select'>
                                        <option>Selecione</option>
                                    </select>
                                </div>
                                <div style={{ padding: "5px", width: "350px" }}>
                                    <label>Interação</label>
                                    <select className='form-select'>
                                        <option>Selecione</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ padding: "5px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                <span style={{ fontSize: "16px", fontWeight: "bold" }}><i className="fa fa-chart-line"></i> Aproveitamento do Fluxo Ativo</span>
                            </div>
                            <ResponsiveContainer width="100%" height="80%">
                                <BarChart
                                    width={"100%"}
                                    height={300}
                                    data={[]}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <YAxis dataKey="total" domain={[0, 20]} />
                                    <XAxis dataKey="dia" />
                                    <Legend />
                                    <Tooltip />
                                    <Bar dataKey="total" fill="#8884d8" stroke="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "15px", width: "49%" }}>
                        <div style={{ height: "450px", padding: "15px 5px", width: "100%" }} className="box-shadow">
                            <div style={{ display: "flex" }}>
                                <div style={{ padding: "5px 10px", width: "350px" }}>
                                    <label>Automação</label>
                                    <select className='form-select'>
                                        <option>Selecione</option>
                                    </select>
                                </div>
                                <div style={{ padding: "5px", width: "350px" }}>
                                    <label>Interação</label>
                                    <select className='form-select'>
                                        <option>Selecione</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ padding: "5px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                <span style={{ fontSize: "16px", fontWeight: "bold" }}><i className="fa fa-chart-line"></i> Aproveitamento do Fluxo Passivo</span>
                            </div>
                            <ResponsiveContainer width="100%" height="80%">
                                <BarChart
                                    width={"100%"}
                                    height={300}
                                    data={[]}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <YAxis dataKey="total" domain={[0, 20]} />
                                    <XAxis dataKey="dia" />
                                    <Legend />
                                    <Tooltip />
                                    <Bar dataKey="total" fill="#8884d8" stroke="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>


            </div>                    
        </ContentWrapper>
    )
}