import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';

import { Modal,  ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'

import api from '../../services/api';
import { IDCOMPANY, ReadDataStorage} from '../../services/auth'
import loadingButton  from '../layouts/Buttons'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalSendMail({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]             = useState(false)
    const [content, setContent]             = useState('')

    const [defaultMail, setDefaultMail]     = useState(false)
    const setData = (obj,clear) => {

    }
   // const emailEditorRef = useRef(null);

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-departament'),document.querySelector('#submit-departament').children[0].classList,document.querySelector('#submit-departament').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {}
                     
                     
            if (editState)
                await api.put('/departament/' + data.iddepartament, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-departament'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-departament'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-departament'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/departament', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-departament'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-departament')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-departament'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-departament'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        setStatistic('modals\\modal-sendmail')
    },[])

    useEffect(() => {

    },[data,editState,showModal])
   
    const onReady = (unlayer) => {
        // editor is ready
        // you can load your template here;
        // the design json can be obtained by calling
        // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    
        // const templateJson = { DESIGN JSON GOES HERE };
        // unlayer.loadDesign(templateJson);
      };

    return (
      
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-envelope"></i> Enviar um Email
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>               
                        <div style={{display:"flex", gap:"5px"}}>   
                            <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                <input type="checkbox" checked={defaultMail} 
                                                       value={defaultMail} 
                                                       onClick={e => setDefaultMail(!defaultMail)} />
                                <span ></span>
                            </label>
                            <span>Usar Modelos de Email?</span>
                        </div>   
                        <div style={{display:"flex", gap:"5px", width:"100%"}}>
                            <div style={{width:"50%"}}>
                                <span>Modelo</span>
                                <select className='form-select'>

                                </select>
                            </div>
                            <div style={{width:"50%"}}>
                                <span>Envia de</span>
                                <select className='form-select'>

                                </select>
                            </div>
                        </div>

                        <div style={{display:"flex", gap:"5px", width:"100%"}}>
                            <div style={{width:"50%"}}>
                                <span>Para</span>
                                <TextInput className="form-control" />
                            </div>
                            <div style={{width:"50%"}}>
                                <span>Título</span>
                                <TextInput className="form-control" />
                            </div>
                        </div>

                        <div style={{ width: "100%", height: "300px", gap: "5px", display: "flex", flexDirection: "column"}}>
                            <span>Corpo do Email</span>
                            <textarea style={{ height: "100%" }} className='form-control' value={content} spellCheck="false" onChange={e => setContent(e.target.value)} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-departament' className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-paper-plane"></em> Enviar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}