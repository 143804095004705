import React, {useEffect} from 'react'
import { useSearchParams } from "react-router-dom";
import setStatistic from '../../services/apiStatistic'


export default function InstagramSuccess({handleReload}) {
    const [searchParams] = useSearchParams();
   
    const code = searchParams.get("code")

    useEffect(() => {
        if (code !== "") {
            
            setTimeout(() => {
                window.close();    
            }, 5000);
        }
        setStatistic('modals\\modal-mynumber-instagram')
    } ,[code])

    return (
        <div style={{display:"flex", justifyContent:"center",alignItems:"center", height:"100vh"}}>
            <div style={{width:"500px", display:"flex", alignItems:"center", flexDirection:"column", gap:"30px"}}>
                <div>
                    <i className='fa fa-circle-check' style={{color:"green", fontSize:"120px"}}/>
                </div>
                <div>
                    <span style={{fontSize:"28px"}}>Instagram conectado com sucesso!</span>
                </div>
            </div>

        </div>
    )
}