import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';

import { Button } from 'reactstrap';

import SHOPIFY from './../../assets/loja/shopify.webp'
import WHATS from './../../assets/loja/whats-sem-bloqueio.webp'
import CHECKOUT from './../../assets/loja/checkout.webp'
import NUVEM from './../../assets/loja/nuvemshop.webp'
import LOJA from './../../assets/loja/lojaintegrada.webp'
import NPS from './../../assets/loja/nps.webp'

export default function PermissionsUser(props) {
    const [loading, setLoading]               = useState(false)

    useEffect(() => {
        
    },[])


    return (    
        <ContentWrapper className="content-wrapper-bottom81 ">
            {loading && (
                <Loading loading={loading} addClass="heigth60vh"/>
            )}
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="box-shadow" style={{display:"flex", gap:"5px", overflowY:"auto", paddingBottom:"80px", height:"85vh", flexWrap:"wrap"}}>
                    <div className="box-shadow" style={{display:"flex", maxWidth:"300px", gap:"10px", flexDirection:"column", height:"350px"}}>
                        <div style={{display:"flex", maxWidth:"300px", gap:"10px"}}>
                            <div>
                                <div style={{minHeight:"160px", maxHeight:"160px"}}>
                                    <img src={CHECKOUT}  alt="" style={{width:"100px"}}/>
                                </div>
                            </div>
                            <div>
                                <div style={{background:"#f7f8f9", borderRadius:"5px", fontWeight:"bold", padding:"5px", textAlign:"center"}}>
                                    <span>Checkout de Pagamento</span>
                                </div>
                                <div style={{fontSize:"12px"}}>
                                    <span>Recebe suas vendas com nosso checkout de pagamento integrado com MercadoPago</span>
                                </div>
                            </div>
                        </div>
                        <div style={{borderTop:"1px solid #cacaca", gap:"5px", display:"flex", flexDirection:"column"}}>
                            <div className='alert alert-success' style={{marginTop:"10px",textAlign:"center", display:"flex", flexDirection:"column"}}>
                                <span style={{fontSize:"18px"}}>R$ 1,00</span>
                                <span style={{fontSize:"14px"}}>Por Transação</span>
                            </div>
                            <div style={{display:"flex"}}>
                                <button className='btn btn-success'><i className='fa fa-cart-plus'></i> Contratar</button>
                            </div>
                        </div>
                    </div>
                    <div className="box-shadow" style={{display:"flex", maxWidth:"300px", gap:"10px", flexDirection:"column", height:"350px"}}>
                        <div style={{display:"flex", maxWidth:"300px", gap:"10px"}}>
                            <div>
                                <div style={{minHeight:"160px", maxHeight:"160px"}}>
                                    <img src={WHATS} alt="" style={{width:"100px"}}/>
                                </div>
                            </div>
                            <div>
                                <div style={{background:"#f7f8f9", borderRadius:"5px", fontWeight:"bold", padding:"5px", textAlign:"center"}}>
                                    <span>WhatsApp Sem Bloqueios</span>
                                </div>
                                <div style={{fontSize:"12px"}}>
                                    <span>Aprenda as melhore técnicas para evitar o bloqueio de contas no whatsapp</span>
                                </div>
                            </div>
                        </div>

                        <div style={{borderTop:"1px solid #cacaca", gap:"5px", display:"flex", flexDirection:"column"}}>
                            <div className='alert alert-success' style={{marginTop:"10px",textAlign:"center", display:"flex", flexDirection:"column"}}>
                                <span style={{fontSize:"18px"}}>R$ 29,90</span>
                                <span style={{fontSize:"14px"}}>Pagamento Único</span>
                            </div>
                            <div style={{display:"flex", gap:"5px"}}>
                                <button className='btn btn-success'><i className='fa fa-cart-plus'></i> Comprar</button>
                                <button className='btn btn-primary'><i className='fa fa-download'></i> Baixar</button>
                            </div>
                        </div>
                    </div>
                    <div className="box-shadow" style={{display:"flex", maxWidth:"300px", gap:"10px", flexDirection:"column", height:"350px"}}>
                        <div style={{display:"flex", maxWidth:"300px", gap:"10px"}}>
                            <div>
                                <div style={{minHeight:"160px", maxHeight:"160px"}}>
                                    <img src={SHOPIFY} alt="" style={{width:"150px", marginTop:"5px"}}/>
                                </div>
                            </div>
                            <div>
                                <div style={{background:"#f7f8f9", borderRadius:"5px", fontWeight:"bold", padding:"5px", textAlign:"center"}}>
                                    <span>Shopify</span>
                                </div>
                                <div style={{fontSize:"12px"}}>
                                    <span>Recupere carrinho, notifique clientes, envie boletos e muito mais...</span>
                                </div>
                            </div>
                        </div>

                        <div style={{borderTop:"1px solid #cacaca", gap:"5px", display:"flex", flexDirection:"column"}}>
                            <div className='alert alert-success' style={{marginTop:"10px",textAlign:"center", display:"flex", flexDirection:"column"}}>
                                <span style={{fontSize:"18px"}}>Consulte</span>
                                <span style={{fontSize:"14px"}}>Mensal</span>
                            </div>
                            <div style={{display:"flex", gap:"5px"}}>
                                <button className='btn btn-success'><i className='fa fa-cart-plus'></i> Contratar</button>
                            </div>
                        </div>
                    </div>
                    <div className="box-shadow" style={{display:"flex", maxWidth:"300px", gap:"10px", flexDirection:"column", height:"350px"}}>
                        <div style={{display:"flex", maxWidth:"300px", gap:"10px"}}>
                            <div>
                                <div style={{minHeight:"160px", maxHeight:"160px"}}>
                                    <img src={NUVEM} alt="" style={{width:"150px", marginTop:"35px"}}/>
                                </div>
                            </div>
                            <div>
                                <div style={{background:"#f7f8f9", borderRadius:"5px", fontWeight:"bold", padding:"5px", textAlign:"center"}}>
                                    <span>NuvemShop</span>
                                </div>
                                <div style={{fontSize:"12px"}}>
                                    <span>Recupere carrinho, notifique clientes, envie boletos e muito mais...</span>
                                </div>
                            </div>
                        </div>

                        <div style={{borderTop:"1px solid #cacaca", gap:"5px", display:"flex", flexDirection:"column"}}>
                            <div className='alert alert-success' style={{marginTop:"10px",textAlign:"center", display:"flex", flexDirection:"column"}}>
                                <span style={{fontSize:"18px"}}>Consulte</span>
                                <span style={{fontSize:"14px"}}>Mensal</span>
                            </div>
                            <div style={{display:"flex", gap:"5px"}}>
                                <button className='btn btn-success'><i className='fa fa-cart-plus'></i> Contratar</button>
                            </div>
                        </div>
                    </div>
                    <div className="box-shadow" style={{display:"flex", maxWidth:"300px", gap:"10px", flexDirection:"column", height:"350px"}}>
                        <div style={{display:"flex", maxWidth:"300px", gap:"10px"}}>
                            <div>
                                <div style={{minHeight:"160px", maxHeight:"160px"}}>
                                    <img src={LOJA} alt="" style={{width:"150px", marginTop:"35px"}}/>
                                </div>
                            </div>
                            <div>
                                <div style={{background:"#f7f8f9", borderRadius:"5px", fontWeight:"bold", padding:"5px", textAlign:"center"}}>
                                    <span>Loja Integrada</span>
                                </div>
                                <div style={{fontSize:"12px"}}>
                                    <span>Recupere carrinho, notifique clientes, envie boletos e muito mais...</span>
                                </div>
                            </div>
                        </div>

                        <div style={{borderTop:"1px solid #cacaca", gap:"5px", display:"flex", flexDirection:"column"}}>
                            <div className='alert alert-success' style={{marginTop:"10px",textAlign:"center", display:"flex", flexDirection:"column"}}>
                                <span style={{fontSize:"18px"}}>Consulte</span>
                                <span style={{fontSize:"14px"}}>Mensal</span>
                            </div>
                            <div style={{display:"flex", gap:"5px"}}>
                                <button className='btn btn-success'><i className='fa fa-cart-plus'></i> Contratar</button>
                            </div>
                        </div>
                    </div>
                    <div className="box-shadow" style={{display:"flex", maxWidth:"300px", gap:"10px", flexDirection:"column", height:"350px"}}>
                        <div style={{display:"flex", maxWidth:"300px", gap:"10px"}}>
                            <div>
                                <div style={{minHeight:"160px", maxHeight:"160px"}}>
                                    <img src={NPS} alt="" style={{width:"100px"}}/>
                                </div>
                            </div>
                            <div>
                                <div style={{background:"#f7f8f9", borderRadius:"5px", fontWeight:"bold", padding:"5px", textAlign:"center"}}>
                                    <span>NPS (PESQUISA DE SATISFAÇÃO)</span>
                                </div>
                                <div style={{fontSize:"12px"}}>
                                    <span>Recupere carrinho, notifique clientes, envie boletos e muito mais...</span>
                                </div>
                            </div>
                        </div>

                        <div style={{borderTop:"1px solid #cacaca", gap:"5px", display:"flex", flexDirection:"column"}}>
                            <div className='alert alert-success' style={{marginTop:"10px",textAlign:"center", display:"flex", flexDirection:"column"}}>
                                <span style={{fontSize:"18px"}}>Consulte</span>
                                <span style={{fontSize:"14px"}}>Mensal</span>
                            </div>
                            <div style={{display:"flex", gap:"5px"}}>
                                <button className='btn btn-success'><i className='fa fa-cart-plus'></i> Contratar</button>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            <div className="modal-footer footer-fixed modal-footer-bottom-10">
                <Button className="btn-ligth-gray" onClick={e => window.removeTab(`tab_${props.id}`,e)}>
                    <em className="fa fa-door-closed"></em> Fechar
                </Button>
            </div>
        </ContentWrapper>
    )
}