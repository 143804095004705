import React, {useState, useEffect} from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';
import InputEmoji from "react-input-emoji";

import { Modal,  ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import setStatistic from '../../services/apiStatistic'
import BACKGROUND from './../../assets/back-chat.png'
import api from '../../services/api';
import { messageCustom,convertURL,getDataNameAndHour } from "./../../components/Utils";
import { useChatData } from './../../components/messageView/chatContext'
import { USER_DATA, ReadDataStorage} from './../../services/auth'

const he = require('he');

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalEditMessage({showModal, handleCloseModal, data, allocMessage}) {
    const [loading, setLoading]             = useState(false)
    const [messageList, setMessageList]     = useState([])
    const [message, setMessage]             = useState('')
    const [idMessage, setIdMessage]         = useState('')
    const [to, setTo]                       = useState('')
    const [number, setNumber]               = useState('')
    const [loadingBtn, setLoadingBtn]       = useState(false)
    const [isEdit, setIsEdit]               = useState(false)

    const { settings } = useChatData()

    const setData = (obj) => {
        setMessageList(obj.messageList || [])
        setNumber(obj.number)
        setIdMessage(obj.idmessage )
        setTo(obj.to)
        setIsEdit(obj.isEdit)
    }
 
    const handleSubmit = async(e) => {
        if ((message === '') )
            return 

        setLoadingBtn(true)
        let bodyMessage
        if (settings.showAttendenceName)
            bodyMessage = '*' + JSON.parse(ReadDataStorage(USER_DATA)).name + '*\n\n' + 
                                message
        else
            bodyMessage = message

 
        let _dataInsert = await allocMessage(idMessage,'')

        api.post('/editMessage',{number    : number?.toString().replace(/[\W_,[a-z]+/g,""),
                                 to        : to.replace(/[\W_,[a-z]+/g,""),
                                 idmessage : idMessage,
                                 message   : he.decode(bodyMessage)})
                   .then(async(response) => {
                       allocMessage(idMessage,'',_dataInsert,true, he.decode(bodyMessage))
                       if (response.data?.status === 500)
                           ShowMessages({message : "Erro ao enviar sua mensagem!",status : 500})
                       else 
                         handleCloseModal()
                   })
                  .catch((error) => {
                      ShowMessages(error)
                  })  
        setMessage('')
          //inputRef.current.focus()
      //  }
    }

    const handleSetText = (e) => {
        setMessage(e)
    }

    const handleKeyPress = (event) => {
        if (event.shiftKey && event.key === 'Enter') {
            event.preventDefault();
            const _message = message + '\n';
            setMessage(_message);
         }
    };

    useEffect(() => {
        setLoadingBtn(false)
        setStatistic('modals\\modal-edit-message')
    },[])

    useEffect(() => {
        setLoadingBtn(false)
        if (showModal)
            setData(data,false)
    },[data,showModal])
   
    return (
      
        <Modal size='xl' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-edit"></i> {isEdit ? "Editar" : "Visualizar"} Mensagem
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>  
                        <div  style={{backgroundImage:`url(${BACKGROUND})`, backgroundColor:"#EFEAE2", width:"500px",backgroundSize:"contain", height:"300px", padding:"5px", overflow:"auto"}}>
                            {messageList.map((_conversation,id) => {
                                return (
                                    <div key={id} className={`index${id}`} style={{width:"100%", justifyContent:"flex-end", display:"flex", marginTop:"10px"}}>                                                
                                        <div style={{display:"flex",flexDirection:"column", marginTop:"-10px",marginLeft:"35%"}}>
                                            <div style={{padding:"10px 10px 5px 10px", background:"#D9FDD3", width:"fit-content", maxWidth:"400px",margin:"5px 15px 0px 15px", display:"flex", borderRadius:"5px", gap:"8px", flexDirection:"column"}}>
                                                {(_conversation.body.indexOf('http://') >= 0 || _conversation.body.indexOf('https://') >= 0) ? 
                                                    <pre className='message-line' style={{textAlign:"right", whiteSpace:"pre-wrap", fontSize:"14px", overflowWrap:"break-word", marginBottom:"0px"}} dangerouslySetInnerHTML={{__html: convertURL(_conversation.body)}}></pre>
                                                : 
                                                    <pre className='message-line' style={{whiteSpace:"pre-wrap", textAlign:"right",fontSize:"14px", overflowWrap:"break-word", marginBottom:"0px"}} dangerouslySetInnerHTML={{__html: messageCustom(_conversation.body,'')}}></pre>                
                                                }
                                                <div style={{justifyContent:"end", display:"flex"}}>
                                                    <span style={{fontSize:"10px"}}>{getDataNameAndHour(new Date(_conversation.create_at))}</span>       
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                               
                                )
                            })}
                        </div>   
                        {isEdit && (         
                            <div style={{display:"flex", width:"100%"}}>
                                <div style={{width:"100%"}}>
                                    <InputEmoji cleanOnEnter shouldReturn={true} className='button-message'  placeholder="Digite sua mensagem" onKeyDown={e => handleKeyPress(e)}  onChange={handleSetText} onEnter={handleSubmit}/>
                                </div>
                            
                                <div className='button-message' title="Enviar Mensagem" onClick={e => handleSubmit(e)}>
                                    <i className={`fa ${loadingBtn ? 'fa fa-spinner fa-spin' : 'fa-paper-plane'}`} disabled={loadingBtn} style={{fontSize:"20px", color:"#858585"}}/>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>         
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}