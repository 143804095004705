import React, { useState, useRef, useEffect } from 'react'

import { getIconeFile, messageCustom, convertURL, ConvertVCard, PopulateConversation, generateRandomString, phone_format } from "./../../components/Utils";
import InputEmoji from "react-input-emoji";
import Waveform from "../../components/waveformer";
import BACKGROUND from './../../assets/back-chat.png'
import ShowMessages from '../../components/Exceptions'
import api from '../../services/api'
import { pdfjs } from 'react-pdf';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { USER_DATA, ReadDataStorage, IDUSER, IDCOMPANY } from './../../services/auth'

import OptionChat from './components/optionChat'

import ModalTransferUser from './../../pages/modals/modal-transfer-user';
import ModalPeopleAnnotation from './../../pages/modals/modal-people-annotation';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { format, subHours, subDays } from 'date-fns'
import Swal from 'sweetalert2'
import USER from './../../assets/user-gray.png'
import { getCookieMessage } from './../Cookies';
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import { generateThumbnail, RepeatRemove, gerarCorHexadecimal, getDataNameAndHour, downloadFile } from './../Utils'
import { setDataDB } from '../../services/idb'

import { AudioRecorder } from 'react-audio-voice-recorder';
import ShowPreviewImage from './showPreview';

import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useChatData } from './chatContext'
import PDF from './../../assets/pdf.png'

const he = require('he');

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
Leaflet.Icon.Default.imagePath =
    '../node_modules/leaflet'

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default function MessageView(props) {
    const [showSearchChat, setShowSearchChat] = useState(false)
    const [showTransferUser, setShowTransferUser] = useState(false)
    const [isOnlyShow, setIsOnlyShow] = useState(false)
    const [textMessage, setTextMessage] = useState('')
    const [userData, setUserData] = useState(null)
    const [attachments, setAttachments] = useState(false)
    const [isRecording, setIsRecording] = useState(false)
    const [disabledChat, setDisabledChat] = useState(false)
    const [audio, setAudio] = useState('')
    const [findCount, setFindCount] = useState(0)
    const [showAnnotation, setShowAnnotation] = useState(false)
    const [actualScroll, setActualScroll] = useState(0)
    const [page, setPage] = useState(1)
    const [loadingInfinite, setLoadingInfinite] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const [commands, setCommands] = useState([])
    const [objAudio, setObjAudio] = useState([])

    const containerRef = useRef(null)
    const fileDialog = useRef(null)
    const imageDialog = useRef(null)

    const { startHuman, endHuman, setStartHuman, to, 
        setIsOpened, isOpened, setEndHuman, settings, showPreview,
        conversation, setConversation, lastPage, userNumber, setShowPreview,
        idAutomationChatHeadHistory, setLastPage, setChatList, filename,
        nameUser, idpeople, photoUser, setTo, chatList, isNotRead, isUnanswered,
        idAttendant, setIdAttendant, setIdAutomationChatHeadHistory,setLoadingChat,
        isGroup, selectedFiles, setSelectedFiles, setFilename, messageType,
        setMessageType } = useChatData()


    const deleteAudio = () => {
        setAudio('')
    }

    const handleSetText = (e) => {
        setShowDropdown(e.startsWith('/'))
        setTextMessage(e)
    }

    const showFiles = async (e) => {
        if (userData)
            setUserData(JSON.parse(ReadDataStorage(USER_DATA)))

        let _files = e?.target?.files || e?.dataTransfer?.files || e
        let _length = e?.target?.files?.length || e?.dataTransfer?.files?.length || e.length

        if (_files.length > 0) {
            let files = [];

            for (let i = 0; i < _length; i++) {
                if (_files[i].size > 41943040) {
                    ShowMessages({ status: 500, message: "O arquivo informado não pode ser maior que 40Mb" })
                } else {
                    files.push({
                        src: URL.createObjectURL(_files[i]),
                        altText: _files[i].name,
                        file: URL.createObjectURL(_files[i]),
                        name: _files[i].name,
                        size: _files[i].size,
                        type: _files[i].type,
                        base64: await convertToBase64(_files[i]),
                        attendance: settings.showAttendenceName ? (userData || JSON.parse(ReadDataStorage(USER_DATA))).name : ''
                    });

                    setFilename(files[0])
                }
            }

            setSelectedFiles(files);
        }

        setAttachments(false)
        setIsOnlyShow(false)
        setShowPreview(true)
        setActualScroll(containerRef?.current?.scrollTop || 0)
    }

    const handleCaptureAudio = async (a) => {
        if (a !== undefined) {
            setAudio(await convertToBase64(a))
            setMessageType(3)
        }
    }

    const handleFile = (type) => {
        setMessageType(2)
        switch (type) {
            case 0:
                if (fileDialog.current)
                    fileDialog.current.click()
                break;
            case 1:
                if (imageDialog.current)
                    imageDialog.current.click()
                break;
            default:
                break;
        }

    }

    const convertToBase64 = async (blob) => {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(blob);
        });
        return result_base64;
    }

    const showImage = (e, _conversation) => {
        setFilename({
            name: _conversation?.name || '',
            src: 'data:' + _conversation.mimetype + ';base64,' + _conversation.file,
            altText: _conversation?.name || '',
            type: _conversation.mimetype,
            filename: _conversation.filename,
            show: true
        })
        setIsOnlyShow(true)
        setShowPreview(true)

        setActualScroll(containerRef.current.scrollTop)
    }

    const allocMessage = async (idmessage, _hash, _dataInsert, edited, newMessage) => {
        let _keyNumber = userNumber.toString().replace(/[\W_,[a-z]+/g, "") + '_' + to.toString().replace(/[\W_,[a-z]+/g, "")
        if (_hash !== '') {
            let _conversation = _dataInsert.find(_filter => {
                return _filter.idmessage === _hash
            })

            _conversation.idmessage = idmessage
            setDataDB(_keyNumber, _dataInsert, null, ReadDataStorage(IDCOMPANY))
            setConversation(_dataInsert)
            return
        }

        if (edited) {
            let _conversation = _dataInsert.find(_filter => {
                return _filter.idmessage === idmessage
            })

            _conversation.edited = true
            _conversation.body = newMessage
            setDataDB(_keyNumber, _dataInsert, null, ReadDataStorage(IDCOMPANY))
            setConversation(_dataInsert)
            return
        }

        let PDF
        if ((messageType === 2) && (filename.type === 'application/pdf'))
            PDF = await generateThumbnail(filename.base64.substr(filename.base64.indexOf(',') + 1, filename.base64.length))

        let bodyMessage = ''
        if (settings.showAttendenceName)
            bodyMessage = '*' + userData.name + '*\n\n' + textMessage
        else
            bodyMessage = textMessage

        let _checkChat = chatList.find(_filter => {
            return parseInt(_filter.idmessagehistoryheader) === parseInt(getCookieMessage('idchat'))
        })

        if (_checkChat) {
            _checkChat.body = messageType === 1 ? bodyMessage : JSON.stringify({
                "mimetype": messageType === 3 ? 'audio/ogg' : filename.type,
                "type": messageType === 3 ? 'ptt' : filename.type.indexOf('image') >= 0 ? 'image' : 'document',
                "filesize": filename.size || 0,
                "file": messageType === 3 ? audio.substr(audio.indexOf(',') + 1, audio.length) : filename.base64.substr(filename.base64.indexOf(',') + 1, filename.base64.length)
            })
            _checkChat.update_at = new Date()
            _checkChat.update_at_format = format(new Date(), 'dd/MM/yyyy HH:mm:ss')
            _checkChat.unread = 0
            _checkChat.hasmedia = messageType !== 1
            _checkChat.isreceive = false
            _checkChat.create_at = new Date()

            let _chats = chatList
            _chats.sort((a, b) => new Date(b.update_at) - new Date(a.update_at));
            setChatList(_chats)
        } else {
            let _List = [{
                body: messageType === 1 ? bodyMessage : JSON.stringify({
                    "mimetype": messageType === 3 ? 'audio/mpeg' : filename.type,
                    "type": messageType === 3 ? 'ptt' : filename.type.indexOf('image') >= 0 ? 'image' : 'document',
                    "filesize": filename.size || 0,
                    "filename": filename.name,
                    "file": messageType === 3 ? audio.substr(audio.indexOf(',') + 1, audio.length) : filename.base64.substr(filename.base64.indexOf(',') + 1, filename.base64.length)
                }),


                hasmedia: messageType !== 1,
                isreceive: false,
                number: phone_format(userNumber),
                original_number: userNumber,
                photo: photoUser,
                notifyname: phone_format(userNumber),
                pinned: false,
                to,
                unread: 0,
                update_at_format: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
                idmessagehistoryheader: getCookieMessage('idchat'),
                update_at: new Date(),
                create_at: new Date(),
                idautomationchatheadhistory: 0
            }]

            let _chats = [..._List, ...chatList]
            _chats.sort((a, b) => new Date(b.update_at) - new Date(a.update_at));
            setChatList(_chats)
        }

        let _populate = PopulateConversation({
            body: messageType === 1 ? bodyMessage : null,
            caption: (messageType > 1 && textMessage !== '') ? textMessage : null,
            mimetype: messageType === 3 ? 'audio/ogg' : filename.type || null,
            type: messageType === 3 ? 'audio' : filename?.type?.indexOf('image') >= 0 ? 'image' : messageType === 1 ? 'chat' : 'document',
            filesize: filename?.size || null,
            filename: filename?.name || null,
            file: messageType === 3 ? audio.substr(audio.indexOf(',') + 1, audio.length) : messageType === 2 ? filename.base64.substr(filename.base64.indexOf(',') + 1, filename.base64.length) : null,
            create_at_format: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
            hasmedia: messageType !== 1,
            isreceive: false,
            reaction: null,
            ack: 0,
            idmessage,
            revoked : false,
            edited : false,
            create_at: new Date(),
            thumbnail: PDF?.URL || null,
            pages: PDF?.pages || null
        }, false)
        let _myChat = [...conversation, _populate]

        _myChat = RepeatRemove(_myChat)


        setDataDB(_keyNumber, _myChat, null, ReadDataStorage(IDCOMPANY))
        setConversation(_myChat)
        return _myChat
    }

    const handleSubmit = async (e) => {
        if ((textMessage === '') && (audio === '') && (selectedFiles.length <= 0))
            return

        let bodyMessage

        if ((settings.showAttendenceName) && (!textMessage.startsWith('/')))
            bodyMessage = '*' + userData.name + '*\n\n' +
                textMessage
        else
            bodyMessage = textMessage

        let _hash = generateRandomString(20)
        let _dataInsert 

        if (!textMessage.startsWith('/'))
            _dataInsert = await allocMessage(_hash, '')

        api.post('/attendance', {
            number: userNumber?.toString().replace(/[\W_,[a-z]+/g, ""),
            to: to.replace(/[\W_,[a-z]+/g, ""),
            messageType: messageType,
            selectedFiles,
            audio,
            bodyMessage: he.decode(bodyMessage)
        })
            .then(async (response) => {
                if ((!textMessage.startsWith('/')) && (response.data.id.id !== ''))
                    allocMessage(response.data.id.id, _hash, _dataInsert)

                if (response.data?.status === 500)
                    ShowMessages({ message: "Erro ao enviar sua mensagem!", status: 500 })
            })
            .catch((error) => {
                ShowMessages(error)
            })
        setTextMessage('')
        setMessageType(1)
        setShowPreview(false)
        setIsOnlyShow(false)
        setActualScroll(containerRef.current.scrollTop + 150)
        setSelectedFiles([])
        setAudio('')
        setIsRecording(false)
        imageDialog.current.value = null
        fileDialog.current.value = null
        //inputRef.current.focus()
        //  }
    }

    const Maps = React.memo(({ props }) => {
        const position = [props.latitude, props.longitude]

        const MapClickHandler = () => {
            useMapEvents({
                click(e) {
                    window.open(`https://www.google.com/maps?q=${props.latitude},${props.longitude}&z=17&hl=pt-BR}`, '_blank')
                },
            });
            return null;
        };

        return (<MapContainer
            scrollWheelZoom={false}
            dragging={false}
            doubleClickZoom={false}
            attributionControl={false}
            zoomControl={false}
            center={position}
            style={{ height: '200px', width: "300px" }}
            zoom={15} >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MapClickHandler />
            <Marker position={position}>
            </Marker>

        </MapContainer>)
    });

    const CountUp = ({ initialDate }) => {
        const [timeLeft, setTimeLeft] = useState(getTimeLeft(initialDate));

        function getTimeLeft(startDate) {
            const now = new Date()

            const difference = now.getTime() - subHours(new Date(startDate), -3).getTime();

            const hours = Math.floor(difference / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            return {
                hours,
                minutes,
                seconds
            };
        }

        useEffect(() => {
            const timer = setInterval(() => {
                setTimeLeft(getTimeLeft(initialDate));
            }, 1000);

            return () => clearInterval(timer);
        }, [initialDate]);

        return (
            <div className='bg alert alert-primary' style={{ padding: "5px", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "0px" }}>
                <i className='fa fa-headset' />
                <span style={{ fontSize: "16px", marginLeft: "5px" }}>{(timeLeft.hours.toString().length === 1 ? '0' : '') + timeLeft.hours}:{(timeLeft.minutes.toString().length === 1 ? '0' : '') + timeLeft.minutes}:{(timeLeft.seconds.toString().length === 1 ? '0' : '') + timeLeft.seconds}</span>
            </div>
        );
    }

    const searchConversation = (value) => {
        let _search = value.toString().toLowerCase()
        let _return = conversation.map(_conversation => {
            if (_conversation.type === 'chat') {
                _conversation.body = _conversation.body.toString().replace('<span style="background:#ebe534">', '').replace('</span>', '')
                return _conversation
            } else
                return _conversation
        })

        let _id = -1
        let i = 0
        if (value !== '') {
            _return = _return.map((_conversation, id) => {
                if (_conversation.type === 'chat') {
                    if (_conversation.body.toString().toLowerCase().indexOf(_search) >= 0) {
                        _conversation.body = _conversation.body.toString().toLowerCase().replace(_search, '<span style="background:#ebe534">' + value + '</span>')
                        _id = id
                        i = i + 1
                    }
                    else
                        _conversation.body = _conversation.body.toString().toLowerCase().replace('<span style="background:#ebe534">', '').replace('</span>', '')
                }
                return _conversation
            })
        }

        setFindCount(i)
        setConversation(_return)//ATUALIZA AS CONVERSAS QUANDO FAZ A BUSCA
        if (_id > -1) {
            let element = document.querySelector('.index' + _id)
            const y = element.getBoundingClientRect().top + window.scrollY - 500;
            containerRef.current.scrollBy({
                top: y,
                behavior: 'smooth'
            });
        } else {
            containerRef.current.scrollBy({
                top: containerRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    }

    const infiniteScroll = async (e) => {
        //console.log(page,lastPage)
        // if (page > lastPage)
        //     return

        const { scrollTop } = e.target;

        let _index = 0

        if (scrollTop === 0 && !loadingInfinite && lastPage > 1) {
            setLoadingInfinite(true)
            _index = await api.post('/getConversation/' + page, { idmessagehistoryheader: getCookieMessage('idchat'), to: to, isNotRead, isUnanswered })
                .then(async (response) => {
                    setPage(page + 1)
                    setLastPage(response.data.lastPage)
                    setConversation([...conversation, ...response.data?.conversation])//ATUALIZA AS CONVERSAS POR INFINTESCROLL
                    setLoadingInfinite(false)
                    return conversation[0].idmessagehistory
                }).catch(error => {
                    ShowMessages(error)
                })


            setTimeout(() => {
                let element = document.querySelector('.message_id_' + _index)
                const y = element.getBoundingClientRect().top + window.scrollY - 500;
                containerRef.current.scrollBy({
                    top: y,

                });
            }, 500)
        }
    }

    const handleKeyPress = (event) => {
        if (event.shiftKey && event.key === 'Enter') {
            event.preventDefault();
            const message = textMessage + '\n';
            setTextMessage(message);
        }

        if (textMessage.startsWith('/') && event.key === 'Tab') {
            event.preventDefault();
            let _filter = commands.filter(_item => {
                return _item.label.toString().toLowerCase().indexOf(textMessage.toString().toLowerCase().substring(1)) >= 0
            })

            if (_filter.length === 1) {
                setTextMessage('/' + _filter[0].label);

            }
        }
    };

    const startAttendante = () => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente iniciar esse atendimento?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post('/attendance/status', {
                    idAutomationChatHeadHistory,
                    number: userNumber,
                    to,
                    idstatus: 1,
                    idpeople
                })
                    .then((response) => {

                        setStartHuman(response.data.time)
                        setEndHuman(null)
                        setIsOpened(true)
                        setIdAutomationChatHeadHistory(response.data.idAutomationChatHeadHistory)
                        setIdAttendant(parseInt(ReadDataStorage(IDUSER)))
                        ShowMessages(response)
                    }).catch(error => {
                        ShowMessages(error)
                    })
            }

        })
    }

    const endAttendante = () => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente encerrar esse atendimento?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post('/attendance/status', {
                    idAutomationChatHeadHistory,
                    idstatus: 2
                })
                    .then((response) => {
                        setIsOpened(false)
                        setEndHuman(response.data.time)
                        ShowMessages(response)
                        setTo('')
                    }).catch(error => {
                        ShowMessages(error)
                    })
            }

        })
    }

    const stopChatBot = async () => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente parar o chatbot para esse interação?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post('/automation/stop/' + idAutomationChatHeadHistory)
                    .then(response => {
                        ShowMessages(response.data)
                    })
                    .catch(error => {
                        ShowMessages(error)
                    })
            }

        })
    }

    const handleCloseModal = () => { setShowTransferUser(false) }
    const handleCloseModalAnnotation = () => { setShowAnnotation(false) }
    const handleCloseModalPreview = () => {
        imageDialog.current.value = null
        fileDialog.current.value = null
        setShowPreview(false)
        setIsOnlyShow(false)
        setSelectedFiles([])
        setFilename({})
    }

    const getDataName = (_date) => {
        if (_date === format(new Date(), 'dd/MM/yyyy'))
            return 'Hoje'
        else if (_date === format(subDays(new Date(), 1), 'dd/MM/yyyy'))
            return 'Ontem'
        else if (_date === format(subDays(new Date(), 2), 'dd/MM/yyyy'))
            return 'Anteontem'
        else
            return _date
    }

    const setLayoutMic = () => {
        let _main = document.querySelector('.audio-recorder')
        let _save = document.querySelector('.audio-recorder-mic ')
        let _img = document.querySelectorAll('.audio-recorder img')

        if (_main)
            _main.style.backgroundColor = 'transparent'

        if (!document.querySelector('.audio-recorder i')) {
            const _iRecord = document.createElement('i');
            const _iPause = document.createElement('i');
            const _iStop = document.createElement('i');

            _iRecord.addEventListener('click', function (event) {
                _img[0].click()
                _main.style.backgroundColor = '#EBEBEB'
                _iRecord.style.display = 'none'
                _iPause.style.display = 'block'
                _iStop.style.display = 'block'
                setIsRecording(true)
            });

            _iPause.addEventListener('click', function (event) {
                _img[1].click()
                _main.style.backgroundColor = '#EBEBEB'

                if (_iPause.classList.length > 0) {
                    if (_iPause.classList[1] === 'fa-circle-pause') {
                        _iPause.classList.remove('fa-circle-pause')
                        _iPause.classList.add('fa-circle-play')
                    } else {
                        _iPause.classList.remove('fa-circle-play')
                        _iPause.classList.add('fa-circle-pause')
                    }
                }
            });

            _iStop.addEventListener('click', function (event) {
                _img[2].click()
                _main.style.backgroundColor = '#EBEBEB'
                _iStop.style.display = 'none'
                _iPause.style.display = 'none'
                _iRecord.style.display = 'block'
                _main.style.backgroundColor = 'transparent'
                _save.click()
                setIsRecording(false)
            });

            _iRecord.className = 'fa fa-microphone'
            _iRecord.style.fontSize = '20px'
            _iRecord.style.color = '#858585'
            _iRecord.title = 'Gravar Áudio'

            _iPause.className = 'fa fa-circle-pause'
            _iPause.style.fontSize = '24px'
            _iPause.style.color = '#858585'
            _iPause.style.display = 'none'
            _iPause.style.padding = '5px'
            _iPause.title = 'Pausar Gravação'

            _iStop.className = 'fa fa-circle-stop'
            _iStop.style.fontSize = '24px'
            _iStop.style.color = '#858585'
            _iStop.style.display = 'none'
            _iStop.style.padding = '5px'
            _iStop.title = 'Finalizar Gravação'

            if (_main) {
                _main.appendChild(_iRecord)
                _main.appendChild(_iPause)
                _main.appendChild(_iStop)
            }

        }

        if (_img.length > 0) {
            _img[0].style.display = 'none'
            _img[1].style.display = 'none'
            _img[2].style.display = 'none'
        }
    }

    const restartAttendance = () => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente reiniciar o atendimento?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/automation/start/' + idAutomationChatHeadHistory)
                         .then(response => {
                            ShowMessages(response.data)
                         })
                        .catch( error => {
                            ShowMessages(error)
                        })    
            }
        })
    }


    useEffect(() => {
        const container = containerRef.current;
        if (container)
            container.scrollTop = actualScroll;
    }, [showPreview]);

    useEffect(() => {
        if (showSearchChat)
            return

        const container = containerRef.current;
        setTimeout(() => {
            if (container)
                container.scrollTop = container.scrollHeight;
        }, 1000)
    }, [conversation]);

    useEffect(() => {
        if (selectedFiles.length <= 0) {
            setShowPreview(false)
            setIsOnlyShow(false)
            setMessageType(1)
        }
    }, [selectedFiles])

    useEffect(() => {
        setUserData(JSON.parse(ReadDataStorage(USER_DATA)))
        if (props.viewMode)
            setDisabledChat(true)
        else if (settings.modeType === 4)
            setDisabledChat(false)
        else
            setDisabledChat([1, 3, 5].includes(settings.modeType) && !isOpened)
    }, [isOpened])

    useEffect(() => {
        const handleDragOver = (e) => {
            e.preventDefault();
        }

        const handleDrop = (e) => {
            e.preventDefault();

            const files = e.dataTransfer.files;

            if (files.length > 0) {
                showFiles(e)
                e.dataTransfer.clearData();
            }
            setMessageType(2)
        };

        const ControlV = async (e) => {
            if (((e.ctrlKey && e.key === 'v') || (e.type === 'paste')) && e.clipboardData) {
                const data = e.clipboardData
                const _items = data.items

                for (const item of _items) {
                    if ((item.type.startsWith('image')) || item.type.startsWith('text/html')) {
                        e.preventDefault();
                        if ((item.kind === 'string') && item.type.startsWith('text/html')) {
                            item.getAsString((html) => {
                                const tempDiv = document.createElement('div');
                                tempDiv.innerHTML = html;
                                const img = tempDiv.querySelector('img');

                                fetch(img.src)
                                    .then(res => res.blob())
                                    .then(blob => {
                                        const fileName = img.alt || 'Imagem.png';
                                        const file = new File([blob], fileName, { type: blob.type });
                                        const dataTransfer = new DataTransfer();
                                        dataTransfer.items.add(file);
                                        setMessageType(2)
                                        showFiles(dataTransfer.files)
                                    })
                                    .catch(err => {
                                        console.error('Erro ao obter a imagem:', err)
                                    })

                                setMessageType(2)
                            });
                        } else {
                            const blob = await item.getAsFile();
                            const file = new File([blob], 'Print de Tela.png', { type: blob.type });
                            const dataTransfer = new DataTransfer();
                            dataTransfer.items.add(file);
                            setMessageType(2)
                            showFiles(dataTransfer.files)
                        }
                        break;
                    }
                }
            }
        }

        if (containerRef.current?.dragover === undefined) {
            containerRef.current?.addEventListener('dragover', handleDragOver);
            containerRef.current?.addEventListener('drop', handleDrop);
            document.body.addEventListener('keydown', ControlV);
            document.body.addEventListener('paste', ControlV);
        }

        return () => {
            document.body.removeEventListener('keydown', ControlV);
            document.body.removeEventListener('paste', ControlV);
        };
    }, [containerRef.current?.dragover, showPreview])

    useEffect(() => {
        if (audio === '')
            setLayoutMic()
    }, [audio])

    useEffect(() => {
        setLayoutMic()
        api.post('/getFastMessages')
            .then((response) => {
                setCommands(response.data || [])
            })
            .catch((error) => {
                ShowMessages(error)
            })
    }, [])

    return (
        <>
            {showPreview && <ShowPreviewImage showModal={showPreview} handleCloseModal={handleCloseModalPreview} filename={filename}
                selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} setFilename={setFilename}
                showDropdown={showDropdown} commands={commands} setShowDropdown={setShowDropdown}
                setTextMessage={setTextMessage} handleKeyPress={handleKeyPress} isOnlyShow={isOnlyShow}
                handleSubmit={handleSubmit} handleSetText={handleSetText} />}
            {showTransferUser && <ModalTransferUser showModal={showTransferUser} handleCloseModal={handleCloseModal} data={{ idAutomationChatHeadHistory, idmessagehistoryheader: getCookieMessage('idchat') }} />}
            {showAnnotation && <ModalPeopleAnnotation showModal={showAnnotation} handleCloseModal={handleCloseModalAnnotation} data={{
                to,
                name: nameUser,
                idpeople,
                idmessagehistoryheader: getCookieMessage('idchat')
            }} />}

            {!props.viewMode && (
                <div style={{ background: "#f7f8f9", padding: "5px", display: "flex", gap: "5px", alignItems: "center", justifyContent: "space-between", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                        <div style={{ marginLeft: "15px" }}>
                            <div>
                                <img alt="" style={{ width: "40px", height: "40px", borderRadius: "50%" }} src={photoUser || USER} ></img>
                            </div>
                            <div style={{ marginTop: "-20px", marginLeft: "30px" }}>
                                <img alt="" style={{ width: "15px", height: "15px", borderRadius: "50%" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/WhatsApp_logo-color-vertical.svg/800px-WhatsApp_logo-color-vertical.svg.png"></img>
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ fontSize: "16px" }}>{/^[0-9]+$/.test(nameUser) ? phone_format(nameUser) : nameUser}</span>
                            </div>
                            {(!isGroup) && (
                                <div style={{ borderRadius: "5px", padding: "5px", border: "1px dashed #cacaca", background: "#fff" }}>{phone_format(to)}</div>
                            )}
                        </div>
                    </div>
                    <div style={{ display: "flex", gap: "3px" }}>
                        {idAutomationChatHeadHistory > 0 && (
                            <div className='alert alert-success' style={{ padding: "3px 7px", marginBottom: "0px", color: "green", fontSize: "16px", display: "flex", alignItems: "center" }} title="Número do atendimento">
                                <i className='fa fa-life-ring' style={{ fontSize: "14px" }} /><span style={{ marginLeft: "5px" }}> #{idAutomationChatHeadHistory}</span>
                            </div>
                        )}
                        {(([1, 3, 5].includes(settings.modeType)) && (startHuman !== null) && (endHuman === null)) && (
                            <div title="Tempo de Atendimento">
                                <CountUp initialDate={startHuman} />
                            </div>
                        )}
                    </div>

                    <div style={{ display: "flex" }}>
                        {showSearchChat && (
                            <div style={{ display: "flex" }}>
                                {findCount > 0 && (
                                    <div style={{ width: "200px", display: "flex", alignItems: "center", gap: "3px" }}>
                                        <span> Foram encontrado(s) </span> <span style={{ fontWeight: "bold" }}> {findCount} </span> <span> termos</span>
                                    </div>
                                )}
                                <button type="button" style={{ background: "transparent", border: "0" }} onClick={e => setShowSearchChat(false)}><i className='fa fa-times' /></button>
                                <input type="text" className='form-control' onBlur={e => e.target.value === '' ? setShowSearchChat(false) : ''} onChange={e => searchConversation(e.target.value)} style={{ maxWidth: "170px" }} />
                            </div>
                        )}
                        {([1, 5].includes(settings.modeType)) && (disabledChat) && (
                            <div className='button-message' style={{ display: "flex", alignItems: "center",paddingLeft:"25px", cursor:"pointer"  }} title="Forçar a parada do chatbot" onClick={e => stopChatBot()}>
                                <i className='fa fa-robot text-warning' style={{ fontSize: "20px" }} />
                            </div>
                        )}

                        {([0, 1, 3, 5].includes(settings.modeType)) && (startHuman) && (endHuman === null) && (idAttendant === parseInt(ReadDataStorage(IDUSER))) && (
                            <div className='button-message' style={{ display: "flex", alignItems: "center", cursor:"pointer" }} title="Encerrar Atendimento" onClick={e => endAttendante()}>
                                <i className='fa fa-circle-stop text-danger' style={{ fontSize: "20px" }} />
                            </div>
                        )}

                        {idAutomationChatHeadHistory > 0 && ( 
                            <div className='button-message' style={{ display: "flex", alignItems: "center",borderRight:"1px dashed #cacaca", cursor:"pointer"  }} title="Continuar Fluxo" onClick={e => restartAttendance()}>
                                <i className='fa fa-forward-step' style={{ fontSize: "20px", color:"#198754" }} />
                            </div>
                        )}
                        <div className='button-message' style={{ display: "flex", alignItems: "center", cursor:"pointer" }} title="Pesquisa de Mensagens" onClick={e => setShowSearchChat(!showSearchChat)}>
                            <i className='fa fa-magnifying-glass' style={{ fontSize: "20px" }} />
                        </div>
                        <div className='button-message' style={{ display: "flex", alignItems: "center", cursor:"pointer" }} title="Anotações do Contato" onClick={e => setShowAnnotation(true)}>
                            <i className='fa fa-note-sticky' style={{ fontSize: "20px" }} />
                        </div>

                        {(idAttendant === parseInt(ReadDataStorage(IDUSER))) && (
                            <div className='button-message' style={{ display: "flex", alignItems: "center", cursor:"pointer" }} title='Transferência de Atendimento' onClick={e => setShowTransferUser(true)}>
                                <i className='fa fa-right-left' style={{ fontSize: "20px" }} />
                            </div>
                        )}
                    </div>
                </div>
            )}

            <>
                {loadingInfinite && (
                    <div style={{ padding: "15px", justifyContent: "center", display: "flex", position: "fixed", top: "49px", width: "150px", background: "#fff", borderBottomLeftRadius: "15px", left: "52%", borderBottomRightRadius: "15px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px" }}>
                        Carregando...
                    </div>
                )}
                <div className={`box-conversation`} ref={containerRef} draggable={false} style={{ backgroundImage: `url(${BACKGROUND})`, backgroundColor: "#EFEAE2", width: "100%", backgroundSize: "contain", height: "500px", padding: "5px" }} onScroll={infiniteScroll}>

                    <div style={{ paddingBottom: "10px" }} className='chat-line' >

                        {
                            conversation.map((_conversation, id) => {
                                if (_conversation.isreceive) {
                                    return (
                                        <div>
                                            {(id - 1) >= 0 && conversation[id].create_at_format.substring(0, 2) !== conversation[id - 1].create_at_format.substring(0, 2) && (
                                                <div style={{ display: "flex", justifyContent: "center", margin: "5px" }}>
                                                    <div style={{ background: "#ffffffe6", padding: "5px 10px", borderRadius: "7px", fontWeight: "bold" }}>
                                                        {getDataName(conversation[id].create_at_format.substring(0, 10))}
                                                    </div>
                                                </div>
                                            )}
                                            {/* <div style={{ display:"flex", justifyContent:"center"}}>
                                                <div className='alert alert-warning' style={{margin:"13px", padding:"5px", borderRadius:"5px", display:"flex", justifyContent:"center",gap:"5px", alignItems:"center", width:"350px"}}>
                                                    <i className='fa fa-robot' style={{color:'#000'}}/>
                                                    <span style={{color:'#000'}}>Inicio de Atendimento por Automação</span>
                                                </div>
                                            </div>
                                            <div style={{ display:"flex", justifyContent:"center"}}>
                                                <div className='alert alert-warning' style={{margin:"13px", padding:"5px", borderRadius:"5px", display:"flex", justifyContent:"center",gap:"5px", alignItems:"center", width:"350px"}}>
                                                    <i className='fa fa-robot' style={{color:'#000'}}/>
                                                    <span style={{color:'#000'}}>Fim de Atendimento por Automação</span>
                                                </div>
                                            </div>
                                            <div style={{ display:"flex", justifyContent:"center"}}>
                                                <div className='alert alert-success' style={{margin:"13px", padding:"5px", borderRadius:"5px", display:"flex", justifyContent:"center",gap:"5px", alignItems:"center", width:"350px"}}>
                                                    <i className='fa fa-headset' style={{color:'#000'}}/>
                                                    <span style={{color:'#000'}}>Inicio de Atendimento por Automação</span>
                                                </div>
                                            </div>
                                            <div style={{ display:"flex", justifyContent:"center"}}>
                                                <div className='alert alert-success' style={{margin:"13px", padding:"5px", borderRadius:"5px", display:"flex", justifyContent:"center",gap:"5px", alignItems:"center", width:"350px"}}>
                                                    <i className='fa fa-headset' style={{color:'#000'}}/>
                                                    <span style={{color:'#000'}}>Fim de Atendimento por Automação</span>
                                                </div>
                                            </div> */}
                                            <div key={id} className={`index${id} whtsID_${_conversation.idmessage} message_id_${_conversation.idmessagehistory}`} style={{ padding: "10px 10px 5px 10px", background: "#FFFFFF", margin: "15px 15px 0px 15px", display: "flex", borderRadius: "5px", gap: "8px", flexDirection: "column", width: "fit-content", maxWidth: "70%" }}>
                                                {isGroup && (
                                                    <div style={{ fontWeight: "bold" }}><span style={{ color: gerarCorHexadecimal(_conversation?.numberusergroup) }}>{_conversation.notifyusergroup} </span><span style={{ color: '#cacaca', fontWeight: "normal", marginLeft: "15px", fontSize: "10px" }}>{phone_format(_conversation?.numberusergroup || '')}</span></div>
                                                )}
                                                <div style={{ display: "flex", flexDirection: "column", marginTop: "-10px" }}>
                                                    <OptionChat props={_conversation}  allocMessage={allocMessage}/>
                                                    <div style={{ padding: "10px 10px 5px 10px", background: "#fff", width: "fit-content", maxWidth: "400px", margin: "5px 15px 0px 0px", display: "flex", borderRadius: "5px", gap: "8px", flexDirection: "column" }}>
                                                        {_conversation.hasmedia ?
                                                            <div>
                                                                {(_conversation.type === 'image' || _conversation.type === 'sticker') && (
                                                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                                                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                                                            {(_conversation.type === 'sticker') ? (
                                                                                <img alt="" src={'data:image/jpg;base64,' + _conversation.file} onClick={e => showImage(e, _conversation)} style={{ cursor: "pointer", maxWidth: "250px", maxHeight: "250px", borderRadius: "15px" }} />
                                                                            ) : (
                                                                                <img alt="" src={'data:image/jpg;base64,' + _conversation.file} onClick={e => showImage(e, _conversation)} style={{ cursor: "pointer", maxWidth: "270px", maxHeight: "500px" }} />
                                                                            )}
                                                                        </div>

                                                                        {(_conversation.caption !== "") && (
                                                                            <pre style={{ marginTop: "10px", whiteSpace: "pre-wrap", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px" }} dangerouslySetInnerHTML={{ __html: messageCustom(_conversation?.caption || '',userNumber) }}></pre>
                                                                        )}
                                                                        <div>
                                                                            <pre style={{ whiteSpace: "pre-wrap", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px" }} dangerouslySetInnerHTML={{ __html: _conversation?.reaction }}></pre>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {(_conversation.type === 'video') && (
                                                                    <div style={{ height: "250px", overflow: "hidden", textAlign: "center" }}>
                                                                        <video controls width={300} height={250}>
                                                                            <source src={`data:video/mp4;base64,${_conversation.file}`} type="video/mp4" />
                                                                            Seu navegador não suporta o elemento de vídeo.
                                                                        </video>
                                                                    </div>
                                                                )}

                                                                {((_conversation.type === 'ptt') || (_conversation.type === 'audio/ogg; codecs=opus') || (_conversation.type === 'audio')) && (
                                                                    <div style={{ width: "250px", overflow: "hidden", textAlign: "center" }}>
                                                                        <Waveform
                                                                            url={_conversation.file}
                                                                            waveFormInstance={`waveFormInstance-${id}`}
                                                                            isModal={true}
                                                                            isBase64={true}
                                                                            objAudio={objAudio}
                                                                            setObjAudio={setObjAudio}
                                                                            backgroundColor="#f7f8f9"
                                                                        ></Waveform>
                                                                    </div>
                                                                )}

                                                                {(_conversation.type === 'document') && (
                                                                    (_conversation.mimetype === 'application/pdf') ?
                                                                        <>
                                                                            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", background: "#f7f8f9", width: "250px", maxWidth: "250px", marginTop: "15px", cursor: "pointer", borderRadius: "5px", padding: "10px" }}>
                                                                                <div style={{ borderRadius: "50%", padding: "5px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                    <img alt="" src={_conversation.thumbnail} style={{ width: "200px", maxWidth: "200px" }} />
                                                                                </div>
                                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                    <div>
                                                                                        {(_conversation.thumbnail === '') && (
                                                                                            <div>
                                                                                                <img src={PDF} alt="" style={{ width: "45px" }} />
                                                                                            </div>
                                                                                        )}
                                                                                        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                                                                            <div style={{ maxWidth: "125px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", marginTop: "15px" }} title={_conversation.filename}>
                                                                                                <span>{_conversation.filename}  </span>
                                                                                            </div>
                                                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "110px" }}>
                                                                                                <span style={{ fontSize: "10px" }}>{(_conversation.filesize / 1024).toFixed(2)} KB  </span>
                                                                                                <span style={{ fontSize: "10px" }}>{_conversation.pages} Página{parseInt(_conversation.pages) > 1 ? 's' : ''}  </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{ padding: "5px", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={e => downloadFile(e, _conversation)}>
                                                                                        <i className='fa fa-circle-down' style={{ fontSize: "34px" }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <pre className='message-line' style={{ whiteSpace: "pre-wrap", textAlign: "start", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px" }} dangerouslySetInnerHTML={{ __html: messageCustom(_conversation.caption && _conversation.caption !== '' ? _conversation.caption : _conversation.body,userNumber) }}></pre>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <div style={{ height: "70px", overflow: "hidden", textAlign: "center" }}>
                                                                            <div style={{ display: "flex", justifyContent: "space-between", background: "#f7f8f9", width: "200px", maxWidth: "200px", marginTop: "15px", cursor: "pointer", padding: "5px" }}>
                                                                                <div style={{ borderRadius: "50%", width: "fit-content", padding: "5px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                    <img alt="" src={getIconeFile(_conversation.mimetype, _conversation.filename)} style={{ width: "30px" }} />
                                                                                </div>
                                                                                <div>
                                                                                    <div style={{ maxWidth: "110px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} title={_conversation.filename}>
                                                                                        <span>{_conversation.filename}  </span>
                                                                                    </div>
                                                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                        <span style={{ fontSize: "10px" }}>{(_conversation.filesize / 1024).toFixed(2)} KB  </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ padding: "5px", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={e => downloadFile(e, _conversation)}>
                                                                                    <i className='fa fa-circle-down' style={{ fontSize: "22px" }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                )}

                                                                {(_conversation.type === 'vcard') && (
                                                                    <ConvertVCard body={_conversation.file} number={userNumber} />
                                                                )}
                                                            </div>
                                                            :
                                                            <>
                                                            
                                                                {(_conversation.type === 'location') ? (
                                                                    <>
                                                                        <Maps props={{ latitude: _conversation.location?.latitude, longitude: _conversation.location?.longitude }} />
                                                                        {((_conversation.location?.description !== '') || (_conversation.location?.name !== '')) && (
                                                                            <pre className='message-line' style={{ whiteSpace: "pre-wrap", textAlign: "start", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px" }}>{_conversation.location?.description || _conversation.location?.name}</pre>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                (_conversation.body.indexOf('http://') >= 0 || _conversation.body.indexOf('https://') >= 0) ?
                                                                    <pre lassName='message-line' style={{ whiteSpace: "pre-wrap", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px", textAlign: "start" }} dangerouslySetInnerHTML={{ __html: convertURL(_conversation.body) }}></pre>
                                                                    :
                                                                    <pre className='message-line' style={{ whiteSpace: "pre-wrap", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px", textAlign: "start" }} dangerouslySetInnerHTML={{ __html: messageCustom(_conversation.body,userNumber) }}></pre>
                                                                )}
                                                            </>
                                                        }
                                                    </div>
                                                    <div style={{ textAlign: "end" }}>
                                                        <div style={{ display: "flex", gap: "15px", justifyContent: `${_conversation.edited ? "space-between" : "flex-end"}`, width: "100%" }}>
                                                            {_conversation.edited && (
                                                                <span style={{ fontSize: "10px" }}>Editada</span>
                                                            )}
                                                            <span style={{ fontSize: "10px" }}>{getDataNameAndHour(new Date(_conversation.create_at))}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {_conversation?.reaction !== null && (
                                                <div style={{ display: "flex", justifyContent: "center", borderRadius: "10px", padding: "5px", width: "40px", background: "#D9FDD3", marginTop: "-8px", marginLeft: "43%" }}>
                                                    <pre style={{ whiteSpace: "pre-wrap", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px" }} dangerouslySetInnerHTML={{ __html: _conversation?.reaction }}></pre>
                                                </div>
                                            )}
                                        </div>
                                    )
                                } else {
                                    return (
                                        <>
                                            {(id - 1) >= 0 && conversation[id].create_at_format.substring(0, 2) !== conversation[id - 1].create_at_format.substring(0, 2) && (
                                                <div style={{ display: "flex", justifyContent: "center", margin: "5px" }}>
                                                    <div style={{ background: "#ffffffe6", padding: "5px 10px", borderRadius: "7px", fontWeight: "bold" }}>
                                                        {getDataName(conversation[id].create_at_format.substring(0, 10))}
                                                    </div>
                                                </div>
                                            )}

                                            <div key={id} className={`index${id} message_id_${_conversation.idmessagehistory}`} style={{ width: "100%", justifyContent: "flex-end", display: "flex", marginTop: "10px" }}>
                                                <div style={{ display: "flex", flexDirection: "column", marginTop: "-10px", marginLeft: "35%" }}>
                                                    <div style={{ padding: "10px 10px 5px 10px", background: "#D9FDD3", width: "fit-content", maxWidth: "400px", margin: "5px 15px 0px 15px", display: "flex", borderRadius: "5px", gap: "8px", flexDirection: "column" }}>
                                                        {(_conversation?.revoked) ? 
                                                            <div style={{display:"flex", gap:"4px", alignItems:"center"}}>
                                                                <i className='fa fa-ban'/>
                                                                <span style={{fontStyle:"italic"}}>Mensagem Apagada</span>
                                                            </div>
                                                        : 
                                                            <>
                                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                    <OptionChat props={_conversation}  allocMessage={allocMessage}/>
                                                                </div>
                                                                {_conversation.hasmedia ?
                                                                    <div>
                                                                        {(_conversation.type === 'image' || _conversation.type === 'sticker') && (
                                                                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                                                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                                                                    {(_conversation.type === 'sticker') ? (
                                                                                        <img alt="" src={'data:image/jpg;base64,' + _conversation.file} onClick={e => showImage(e, _conversation)} style={{ cursor: "pointer", maxWidth: "250px", maxHeight: "250px", borderRadius: "15px" }} />
                                                                                    ) : (
                                                                                        <img alt="" src={'data:image/jpg;base64,' + _conversation.file} onClick={e => showImage(e, _conversation)} style={{ cursor: "pointer", maxWidth: "270px", maxHeight: "500px" }} />
                                                                                    )}
                                                                                </div>

                                                                                {(_conversation.caption !== "") && (
                                                                                    <pre style={{ marginTop: "10px", whiteSpace: "pre-wrap", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px" }} dangerouslySetInnerHTML={{ __html: messageCustom(_conversation?.caption || '',userNumber) }}></pre>
                                                                                )}
                                                                            </div>
                                                                        )}

                                                                        {(_conversation.type === 'video') && (
                                                                            <div style={{ height: "250px", overflow: "hidden", textAlign: "center" }}>
                                                                                <video controls width={300} height={250}>
                                                                                    <source src={`data:video/mp4;base64,${_conversation.file}`} type="video/mp4" />
                                                                                    Seu navegador não suporta o elemento de vídeo.
                                                                                </video>
                                                                            </div>
                                                                        )}

                                                                        {((_conversation.type === 'ptt') || (_conversation.type === 'audio/ogg; codecs=opus') || (_conversation.type === 'audio')) && (
                                                                            <div style={{ width: "250px", overflow: "hidden", textAlign: "center" }}>
                                                                                <Waveform
                                                                                    url={_conversation.file}
                                                                                    waveFormInstance={`waveFormInstance-${id}`}
                                                                                    isModal={true}
                                                                                    isBase64={true}
                                                                                    objAudio={objAudio}
                                                                                    setObjAudio={setObjAudio}
                                                                                ></Waveform>
                                                                            </div>
                                                                        )}

                                                                        {(_conversation.type === 'document') && (
                                                                            (_conversation.mimetype === 'application/pdf') ?
                                                                                <>
                                                                                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", background: "#f7f8f9", width: "250px", maxWidth: "250px", marginTop: "15px", cursor: "pointer", borderRadius: "5px", padding: "10px" }}>
                                                                                        <div style={{ borderRadius: "50%", padding: "5px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                            <img alt="" src={_conversation.thumbnail} style={{ width: "200px", maxWidth: "200px" }} />
                                                                                        </div>
                                                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                            <div>
                                                                                                {(_conversation.thumbnail === '') && (
                                                                                                    <div>
                                                                                                        <img src={PDF} alt="" style={{ width: "45px" }} />
                                                                                                    </div>
                                                                                                )}
                                                                                                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                                                                                    <div style={{ maxWidth: "125px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", marginTop: "15px" }} title={_conversation.filename}>
                                                                                                        <span>{_conversation.filename}  </span>
                                                                                                    </div>
                                                                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "110px" }}>
                                                                                                        <span style={{ fontSize: "10px" }}>{(_conversation.filesize / 1024).toFixed(2)} KB  </span>
                                                                                                        <span style={{ fontSize: "10px" }}>{_conversation.pages} Página{parseInt(_conversation.pages) > 1 ? 's' : ''}  </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div style={{ padding: "5px", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={e => downloadFile(e, _conversation)}>
                                                                                                <i className='fa fa-circle-down' style={{ fontSize: "34px" }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <pre className='message-line' style={{ whiteSpace: "pre-wrap", textAlign: "right", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px" }} dangerouslySetInnerHTML={{ __html: messageCustom(_conversation.caption && _conversation.caption !== '' ? _conversation.caption : _conversation.body,userNumber) }}></pre>
                                                                                    </div>
                                                                                </>
                                                                            :
                                                                                <div style={{ height: "57px", overflow: "hidden", textAlign: "center" }}>
                                                                                    <div style={{ display: "flex", justifyContent: "space-between", background: "#f7f8f9", width: "200px", maxWidth: "200px", marginTop: "15px", cursor: "pointer", padding: "5px" }}>
                                                                                        <div style={{ borderRadius: "50%", width: "fit-content", padding: "5px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                            <img alt="" src={getIconeFile(_conversation.mimetype, _conversation.filename)} style={{ width: "30px" }} />
                                                                                        </div>
                                                                                        <div>
                                                                                            <div style={{ maxWidth: "110px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} title={_conversation.filename}>
                                                                                                <span>{_conversation.filename}  </span>
                                                                                            </div>
                                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                                <span style={{ fontSize: "10px" }}>{(_conversation.filesize / 1024).toFixed(2)} KB  </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{ padding: "5px", display: "flex", alignItems: "center", justifyContent: "center" }} onClick={e => downloadFile(e, _conversation)}>
                                                                                            <i className='fa fa-circle-down' style={{ fontSize: "22px" }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                        )}

                                                                        {(_conversation.type === 'vcard') && (
                                                                            <ConvertVCard body={_conversation.file} number={userNumber} />
                                                                        )}
                                                                    </div>
                                                                :
                                                                    <>
                                                                        {(_conversation.type === 'location') ? (
                                                                            <>
                                                                                <Maps props={{ latitude: _conversation.location.latitude, longitude: _conversation.location.longitude }} />
                                                                                {((_conversation.location?.description !== '') || (_conversation.location?.name !== ''))&& (
                                                                                    <pre className='message-line' style={{ whiteSpace: "pre-wrap", textAlign: "right", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px" }}>{_conversation.location?.description || _conversation.location?.name}</pre>
                                                                                )}
                                                                            </>
                                                                        ) : 
                                                                            (_conversation.body.indexOf('http:') >= 0 || _conversation.body.indexOf('https:') >= 0) ?
                                                                                <pre className='message-line' style={{ textAlign: "right", whiteSpace: "pre-wrap", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px" }} dangerouslySetInnerHTML={{ __html: convertURL(_conversation.body) }}></pre>
                                                                            :
                                                                                <pre className='message-line' style={{ whiteSpace: "pre-wrap", textAlign: "right", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px" }} dangerouslySetInnerHTML={{ __html: messageCustom(_conversation.body,userNumber) }}></pre>
                                                                        }
                                                                    </>
                                                                }
                                                                <div style={{ justifyContent: "end", display: "flex" }}>
                                                                    <div style={{ display: "flex", gap: "15px", justifyContent: `${_conversation.edited ? "space-between" : "flex-end"}`, width: "100%" }}>
                                                                        {_conversation.edited && (
                                                                            <span style={{ fontSize: "10px" }}>Editada</span>
                                                                        )}
                                                                        <span style={{ fontSize: "10px" }}>{getDataNameAndHour(new Date(_conversation.create_at))}</span>
                                                                    </div>

                                                                    {_conversation.ack === 0 && (
                                                                        <div style={{ marginLeft: "10px" }}>
                                                                            <i className='fa fa-spin fa-spinner' />
                                                                        </div>
                                                                    )}
                                                                    {_conversation.ack === 1 && (
                                                                        <div style={{ marginLeft: "10px" }}>
                                                                            <i className='fa fa-check' />
                                                                        </div>
                                                                    )}
                                                                    {_conversation.ack === 2 && (
                                                                        <div style={{ marginLeft: "10px" }}>
                                                                            <i className='fa fa-check-double' />
                                                                        </div>
                                                                    )}
                                                                    {[3, 4].includes(_conversation.ack) && (
                                                                        <div style={{ marginLeft: "10px" }}>
                                                                            <i className='fa fa-check-double' style={{ color: "#53bdeb" }} />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </> 
                                                        }
                                                    </div>
                                                    
                                                    {_conversation?.reaction !== null && (!_conversation?.revoked) && (
                                                        <div style={{ display: "flex", justifyContent: "center", borderRadius: "10px", padding: "5px", width: "40px", background: "#fff", marginTop: "-8px", marginLeft: "40%" }}>
                                                            <pre style={{ whiteSpace: "pre-wrap", fontSize: "14px", overflowWrap: "break-word", marginBottom: "0px" }} dangerouslySetInnerHTML={{ __html: _conversation?.reaction }}></pre>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            })

                        }
                    </div>
                </div>

                {!props.viewMode && (

                    <div style={{ display: "flex", padding: "5px", background: "#f7f8f9" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "end", height: `${disabledChat ? "100px" : "52px"}`}}>

                            <div style={{ display: "flex", width: "100%", justifyContent: 'end', marginRight: "10px" }}>
                                {disabledChat ? (
                                    <div className='bg alert alert-warning'  style={{ width: "100%", display: "flex", borderRadius: "5px", justifyContent: 'center', alignItems: "center", flexDirection:"column" }}>
                                        <div style={{ width: "100%", display: "flex", borderRadius: "5px", justifyContent: 'center', alignItems: "center" }}>
                                            <i className='fa fa-triangle-exclamation' style={{ color: "#000", fontSize: "16px" }} />
                                            <span style={{ color: "#000", fontSize: "16px" }}> Inicie o atendimento no botão abaixo para começar a enviar mensagens</span>
                                        </div>
                                        {([0, 1, 3, 5].includes(settings.modeType)) && (disabledChat) && (
                                            <div className='button-message' style={{ display: "flex", alignItems: "center", paddingRight:"25px", cursor:"pointer" }} title="Iniciar Atendimento" onClick={e => startAttendante()}>
                                                <i className='fa fa-play text-success' style={{ fontSize: "26px" }} />
                                            </div>
                                        )}
                                    </div>
                                ) :
                                    ((startHuman) && (endHuman === null) && (idAttendant !== parseInt(ReadDataStorage(IDUSER)))) ?
                                        <div className='bg alert alert-danger' style={{ width: "100%", display: "flex", borderRadius: "5px", justifyContent: 'center', alignItems: "center" }}>
                                            <i className='fa fa-triangle-exclamation' style={{ color: "#fff", fontSize: "16px" }} />&nbsp;
                                            <span style={{ color: "#fff", fontSize: "16px" }}>Este chamado já está sendo atendido por outro atendente{idAttendant}</span>
                                        </div>
                                        :
                                        <>
                                            {(!isRecording) && (audio === '') && (
                                                <div style={{ width: "100%", display: "flex", flexDirection: 'end' }}>
                                                    <div style={{ width: "100%" }}>
                                                        {showDropdown && (
                                                            <div className='box-shadow' style={{ overflowX: 'auto', background: "#fff", padding: "0px", marginLeft: "30px", position: "fixed", bottom: "50px", width: "300px", maxHeight: "200px" }}>
                                                                {

                                                                    commands.map((_item, key) => {
                                                                        if (_item.label.toString().toLowerCase().indexOf(textMessage.toString().toLowerCase().substring(1)) >= 0) {

                                                                            return (
                                                                                <div key={key} className='box-fast-message' onClick={e => {
                                                                                    setShowDropdown(false)
                                                                                    setTextMessage('/' + _item.label)
                                                                                }}>
                                                                                    <span>{_item.label}</span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                            </div>
                                                        )}
                                                        <InputEmoji id="edit-message" cleanOnEnter shouldReturn={true} className='button-message' placeholder="Digite sua mensagem" onKeyDown={e => handleKeyPress(e)} value={textMessage} onChange={handleSetText} onEnter={handleSubmit} />
                                                    </div>
                                                </div>
                                            )}
                                            {(audio !== '') && (
                                                <div style={{ width: "100%", display: "flex", flexDirection: 'end', justifyContent: "end", background: "#f7f8f9" }}>
                                                    <div style={{ width: "250px", overflow: "hidden", textAlign: "center", display: "flex" }}>
                                                        <div style={{ width: "250px" }}>
                                                            <Waveform
                                                                url={(audio.substring(audio.indexOf(',') + 1))}
                                                                waveFormInstance={`waveFormInstance-${4545}`}
                                                                isModal={true}
                                                                maxSize={450}
                                                                isBase64={true}
                                                                objAudio={objAudio}
                                                                setObjAudio={setObjAudio}
                                                            ></Waveform>
                                                        </div>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <div className='button-message' title="Excluir Áudio" onClick={e => deleteAudio()}>
                                                                <i className='fa fa-trash text-danger' style={{ fontSize: "20px" }} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )

                                            }
                                            <div style={{ display: "flex" }}>
                                                {(audio === '') && (
                                                    <>
                                                        <SpeedDial
                                                            //className={classes.speedDial}
                                                            open={attachments}
                                                            hidden
                                                            style={{ position: "fixed", marginTop: "-110px" }}
                                                            direction='up'
                                                            onOpen={e => setAttachments(true)}
                                                            onClose={e => setAttachments(false)}
                                                            ariaLabel="Anexar">
                                                            <SpeedDialAction
                                                                icon={<InsertPhotoIcon />}
                                                                tooltipTitle="Fotos e Videos"
                                                                className='bg bg-primary'
                                                                onClick={() => handleFile(1)} />
                                                            <SpeedDialAction
                                                                icon={<InsertDriveFileIcon />}
                                                                tooltipTitle="Documentos"
                                                                className='bg bg-success'
                                                                onClick={() => handleFile(0)} />
                                                        </SpeedDial>
                                                        <input type="file" multiple accept="application/*, text/*, .pdf, .doc, .xls, .docx, .xlsx" style={{ display: 'none' }} id="files" ref={fileDialog} onChange={showFiles} />
                                                        <input type="file" multiple accept="image/*,video/*" style={{ display: 'none' }} id="images" ref={imageDialog} onChange={showFiles} />
                                                        {settings?.allowFiles && !isRecording && (
                                                            <>
                                                                <div className='button-message' title="Opções de Anexo" onClick={e => setAttachments(!attachments)}>
                                                                    <i className='fa fa-paperclip' style={{ fontSize: "20px", color: "#858585" }} />
                                                                </div>
                                                            </>
                                                        )}

                                                        {settings?.allowAudio && (
                                                            <>
                                                                <AudioRecorder
                                                                    onRecordingComplete={handleCaptureAudio}
                                                                    audioTrackConstraints={{
                                                                        noiseSuppression: true,
                                                                        echoCancellation: true,
                                                                    }}
                                                                    onNotAllowedOrFound={(err) => {
                                                                        ShowMessages({status : 500, message : "Microfone não encontrado"})
                                                                        if ((document.querySelectorAll('.fa.fa-circle-stop').length > 0) && (document.querySelectorAll('.fa.fa-circle-stop')[0].style.display !== 'none')) {
                                                                            document.querySelectorAll('.fa.fa-circle-stop')[0].click()
                                                                            setIsRecording(false)
                                                                        }
                                                                    }}
                                                                    downloadOnSavePress={false}
                                                                    downloadFileExtension="mp3"
                                                                    mediaRecorderOptions={{
                                                                        audioBitsPerSecond: 128000,
                                                                    }}
                                                                    showVisualizer={true}
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {!isRecording && (
                                                    <div className='button-message' title="Enviar Mensagem" onClick={e => handleSubmit(e)}>
                                                        <i className='fa fa-paper-plane' style={{ fontSize: "20px", color: "#858585" }} />
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                }
                            </div>

                        </div>
                    </div>
                )}
            </>
        </>)
}


